import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate,  } from "react-router-dom";
import { Typography, Box, Fab } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import Paths from '@app/routes';

import { Header } from "@components/Header";
import { Content } from "@components/Content";
import { LazyScrollingContent } from '@components/LazyScrollingContent';

import { successChannel } from '@channels/globalNotificationChannel'

import { commonSelector } from '@features/common/slices/commonSlice'

import { ThumbnailPost } from '@features/post/components/ThumbnailPost';
import { PostCreationMenu } from '@features/post/components/PostCreationMenu';
import { defaultThumbnailPostWidth, computeThumbnailPostSize } from '@features/post/helpers/post.helper';
import { Post } from "@features/post/models";
import { postSelector, postSlice } from '@features/post/slices'
import { useConfirm } from 'material-ui-confirm';


export const ViewPosts = () => {
    const { t } = useTranslation('view_posts');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const confirm = useConfirm();
    const { posts, hasMorePosts } = useAppSelector(postSelector);
    const [ multiSelectionActivated, setMultiSelectionActivated ] = useState<boolean>(false);
    const [ selectedPostIds, setSelectedPostIds ] = useState<Array<string>>([]);

    const { containerSize } = useAppSelector(commonSelector);
    const [postSize, setPostSize] = useState<number>(defaultThumbnailPostWidth);

    /**
     * 
     */
    useEffect(() => {
        setPostSize(computeThumbnailPostSize(containerSize).postSize);
    }, [containerSize]);

    /**
     * 
     * @param post 
     */
    const onPostClick = (post: Post) => {
        if(multiSelectionActivated) {
            togglePostSelection(post);
        } else {
            navigate(generatePath(Paths.Authenticated.post, {"postId": post.id}));
        }
    }

    const onPostLongPress = (post: Post) => {
        if(multiSelectionActivated) {
            togglePostSelection(post);
        } else {
            setMultiSelectionActivated(true);
            togglePostSelection(post);
        }
    }

    const togglePostSelection = (post: Post) => {
        if(selectedPostIds.includes(post.id)) {
            setSelectedPostIds(selectedPostIds.filter(id => id !== post.id));
        } else {
            setSelectedPostIds([...selectedPostIds, post.id]);
        }
    }

    const onCancelMultiSelection = () => {
        setSelectedPostIds([]);
        setMultiSelectionActivated(false);
    }

    const onDeleteMultiSelection = async () => {
        if(selectedPostIds.length > 0) {
            await confirm({description: t('confirm_delete_posts')});
            await dispatch(postSlice.deletePosts(selectedPostIds));
            successChannel.emit(t('notification_delete_success'));
        }
        onCancelMultiSelection();
    }

    const loadPosts = async () => {
        dispatch(postSlice.loadPosts());
    };

    return (
        <>
            <Header>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                <LazyScrollingContent height='100%' loadNext={loadPosts} items={posts} hasOlderItems={hasMorePosts} noElementText={t('no_post')}
                    render={(posts: Array<Post>) => 
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                        {
                            posts.map((post) => (<ThumbnailPost post={post} 
                                size={postSize} 
                                key={post.id} 
                                onClick={onPostClick} 
                                onLongPress={onPostLongPress} 
                                checked={selectedPostIds.includes(post.id)} 
                                selectable={multiSelectionActivated}/>))
                        }
                    </div>
                }/>
                
                {multiSelectionActivated && <Box sx={{position: 'fixed', right: theme.spacing(2), bottom:  theme.spacing(2)}}>
                    <Fab size="large" color="default" sx={{mr: 2}} onClick={onCancelMultiSelection}>
                        <ClearIcon />
                    </Fab>
                    <Fab size="large" color="error" onClick={onDeleteMultiSelection}>
                        <DeleteIcon />
                    </Fab>
                </Box>}

                {!multiSelectionActivated && 
                    <PostCreationMenu 
                        displayTooltip={posts != null && posts.length === 0}
                        instaPath={Paths.Authenticated.instagram_posts}
                        uploadPath={Paths.Authenticated.postCreation} />
                }
            </Content>
        </>
    );
}