import axios from '@app/axios';
import { CircleUser, CircleUserRole } from '@features/circle/models';

/**
 * 
 * @param circleId 
 * @returns 
 */
const findAll = async (circleId: string): Promise<Array<CircleUser>> => {
    const { data } = await axios.get(`/circles/${circleId}/users`);
    return data;
}

/**
 * 
 * @param circleId 
 * @param users
 * @returns 
 */
const addUsers = async (circleId: string, users: Array<CircleUser>) => {
    await axios.post(`/circles/${circleId}/users`, {
        users: users.map(u => {return {id: u.id, role: u.role}})
    });
};

/**
 * 
 * @param circleId 
 * @param users
 * @returns 
 */
 const removeUser = async (circleId: string, userId: string) => {
    await axios.delete(`/circles/${circleId}/users/${userId}`);
};

/**
 * 
 * @param circleId 
 */
const leaveCircle = async (circleId: string) => {
    await axios.delete(`/circles/${circleId}/users`);
};

/**
 * 
 * @param circleId 
 * @param userId 
 * @param role 
 */
const updateUser = async (circleId: string, userId: string, role: CircleUserRole) => {
    await axios.put(`/circles/${circleId}/users/${userId}`, {role})
}

/**
 * 
 * @param circleId 
 * @param postId 
 */
const updateLastReadPost = async(circleId: string, postId: string) => {
    await axios.put(`circles/${circleId}/users/posts/read`, {postId});
};

/**
 * 
 * @param circleId 
 */
const markAsRead = async (circleId: string) => {
    await axios.put(`/circles/${circleId}/users/posts/read/all`);
};

const service = {
    findAll,
    addUsers,
    removeUser,
    leaveCircle,
    updateUser,
    updateLastReadPost,
    markAsRead
};

export default service;