import { generatePath, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import PostCreation from "@features/post/components/PostCreation";
import { Post } from "@features/post/models";

import { circlePostSlice, circleSelector } from '@features/circle/slices';

export const CreateCirclePost = () => {
    const { circle } = useAppSelector(circleSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const submitSteps = [
        {
            label: 'progress_add_to_circle',
            process: async (post: Post) => {
                await dispatch(circlePostSlice.addPost(circle!.id, post));
            }
        }
    ];

    const navigateAfterCreation = (post: Post) => {
        navigate(generatePath(Paths.Authenticated.circleAddPost, {'circleId': circle!.id}));
    }

    return (
        <PostCreation additionalSubmitSteps={submitSteps} navigate={navigateAfterCreation}/>
    )
}