import axios from '@app/axios';
import { ContactInvitation } from '@features/contact/models';

interface Invitations {
    receivedInvitations: Array<ContactInvitation>
    sentInvitations: Array<ContactInvitation>
}

const loadInvitations = async (): Promise<Invitations> => {
    const { data } = await axios.get('/contacts/invitations');
    return data;
};

/**
 * 
 * @param email 
 */
const sendExternalInvitation = async (email: string) => {
    await axios.post('/contacts/invitations/external', {email});
};

/**
 * 
 * @param contactId 
 */
const sendInternalInvitation = async (contactId: string): Promise<ContactInvitation> => {
    const { data } = await axios.post('/contacts/invitations/internal', {
        invitedUserId: contactId
    });
    return data;
};

/**
 * 
 * @param invitationId 
 */
const cancelInvitation = async(invitationId: string) => {
    await axios.put(`/contacts/invitations/${invitationId}/cancel`);
};

/**
 * 
 * @param invitationId 
 */
const acceptInvitation = async(invitationId: string) => {
    await axios.put(`/contacts/invitations/${invitationId}/accept`);
};

/**
 * 
 * @param invitationId 
 */
const refuseInvitation = async(invitationId: string) => {
    await axios.put(`/contacts/invitations/${invitationId}/refuse`);
};

const service = {
    loadInvitations,
    sendExternalInvitation,
    sendInternalInvitation,
    cancelInvitation,
    acceptInvitation,
    refuseInvitation
};

export default service;