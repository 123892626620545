import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction, AppThunkDispatch } from "@app/hooks";
import { Post, PostStatus } from '@features/post/models';
import postService from '@features/post/services/postService'
import circlePostSlice from '@features/circle/slices/circlePostSlice';

export interface PostState {
  posts: Array<Post> | null,
  loadingPage: number
  hasMorePosts: boolean
}

const initialState: PostState = {
  posts: null,
  loadingPage: 0,
  hasMorePosts: true
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    initialPostsLoad: (state, action: PayloadAction<{hasNext: boolean , data: Array<Post>}>) => {
      state.posts = action.payload.data;
      state.hasMorePosts = action.payload.hasNext;
      state.loadingPage = state.loadingPage + 1;
    },
    addPosts: (state, action: PayloadAction<{hasNext: boolean , data: Array<Post>}>) => {
      if(state.posts == null) {
        state.posts = [];
      }
      
      state.posts = state.posts.concat(action.payload.data);
      state.hasMorePosts = action.payload.hasNext;
      state.loadingPage = state.loadingPage + 1;
    },
    addNewCreatedPost: (state, action: PayloadAction<Post>) => {
      if(state.posts == null) {
        state.posts = [];
      }
      state.posts = [action.payload, ...state.posts];
    },
    activatePost: (state, action: PayloadAction<Post>)=> {
      const post = state.posts?.find(p => p.id === action.payload.id);
      if(post && post.status === PostStatus.PROCESSING) {
        post.status = PostStatus.ACTIVE;
        post.medias = action.payload.medias;
      }
    },
    resetPostsState: () => {
      return initialState;
    },
    removePosts: (state, action: PayloadAction<string[]>) => {
      if(state.posts) {
        state.posts = state.posts?.filter(p => !action.payload.includes(p.id));
      }
    },
    updatePost: (state, action: PayloadAction<{id: string, description: string}>) => {
      const post = state.posts?.find(p => p.id === action.payload.id);
      if(post) {
        post.description = action.payload.description;
      }
    }
  },
});

/**
 * 
 * @param page 
 * @returns 
 */
const loadPosts = (): AppThunkAction<Promise<{data: Array<Post>, hasMore: boolean}>> => {
  return async (dispatch, getState) => {
    const { loadingPage } = getState().post;

    const posts =  await postService.loadPosts(loadingPage);
    if(loadingPage === 0) {
      await dispatch(postSlice.actions.initialPostsLoad({hasNext: posts.hasNext, data: posts.posts}));
    } else {
      await dispatch(postSlice.actions.addPosts({hasNext: posts.hasNext, data: posts.posts}));
    }

    return {data: posts.posts, hasMore: posts.hasNext};
  }
};

/**
 * 
 * @param id 
 * @returns 
 */
const addNewCreatedPost = (id: string): AppThunkAction => {
  return async (dispatch) => {
    const post = await postService.getById(id);
    await dispatch(postSlice.actions.addNewCreatedPost(post))
    if(post.status === PostStatus.PROCESSING) {
      setRefreshPostTimeout(dispatch, id, 0);
    }
  };
};

const setRefreshPostTimeout = (dispatch: AppThunkDispatch, id: string, count: number) => {
  setTimeout(async () => {
    const polledPost = await postService.getById(id);
    if(polledPost.status === PostStatus.ACTIVE) {
      await dispatch(postSlice.actions.activatePost(polledPost));
    } else {
      if(count < 5) {
        setRefreshPostTimeout(dispatch, id, count + 1);
      } else {
        console.log(`Post hasn't been activated, stop auto refresh`);
      }
    }
  }, 2000);
};

/**
 * 
 * @param id 
 * @returns 
 */
const deletePost = (id: string): AppThunkAction => {
  return async (dispatch) => {
    await postService.deletePost(id);
    await dispatch(postSlice.actions.removePosts([id]));
    await dispatch(circlePostSlice.resetStateIfPostsPresent([id]));
  };
};

/**
 * 
 * @param ids 
 */
const deletePosts = (ids: string[]): AppThunkAction => {
  return async (dispatch) => {
    await postService.deletePosts(ids);
    await dispatch(postSlice.actions.removePosts(ids));
    await dispatch(circlePostSlice.resetStateIfPostsPresent(ids));
  };
};

/**
 * 
 * @param post 
 */
const updatePost = (postId: string, description: string): AppThunkAction => {
  return async (dispatch) => {
    await postService.updatePost(postId, description);
    await dispatch(postSlice.actions.updatePost({id: postId, description: description}));
  };
};

export const postSelector = (state: { post: PostState }) => state.post;

export const reducer = postSlice.reducer;

const slice = {
  addNewCreatedPost,
  loadPosts,
  deletePost,
  deletePosts,
  updatePost,
  resetPostsState: postSlice.actions.resetPostsState
};

export default slice;