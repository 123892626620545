import { Box, IconButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CameraStyle from './CameraCaptureStyle';
import { dataURLtoFile } from "@helpers/file.helper";

const finalWidth = 1080;

interface CameraPhotoCapture {
    onCancel: () => void,
    onValidate: (file: File) => void
}

const CameraPhotoCapture = (props: CameraPhotoCapture) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const containerRef = useRef<HTMLElement>(null);

    const [hasFrontCamera, setHasFrontCamera] = useState<boolean>(false);
    const [cameraFacingMode, setCameraFacingMode] = useState<string>('environment');
    const [displayPicture, setDisplayPicture] = useState<boolean>(false);
    const [data, setData] = useState<string | undefined>();
    const stream = useRef<MediaStream | null>(null);

    useEffect(() => {
        (async() => {
            // now we have permission to see the camera devices, check if there are more than 1. If yes, let's consider there is a "user" one
            // and display a button to switch camera
            const devices = await navigator.mediaDevices.enumerateDevices();
            setHasFrontCamera(devices.filter(d => d.kind === 'videoinput').length > 1);
        })();

        return () => stopCamera();
    }, []);

    /**
     * Start the camera device and play it in the video to see the preview
     */
    const startCamera = async () => {
        stream.current  = await navigator.mediaDevices.getUserMedia({ video: {facingMode: cameraFacingMode}, audio: false });
        videoRef.current!.srcObject = stream.current;
        await videoRef.current!.play();
    };

    /**
     * Close all media streams linked to the preview 
     */
    const stopCamera = () => {
        if(stream.current) {
            stream.current.getTracks().forEach(track => track.stop());
        }
        if(videoRef.current) {
            videoRef.current!.srcObject = null;
        }
    }

    /**
     * Switch the facing mode (only works if several camera available for mobile)
     */
    const switchCamera = async() => {
        if(cameraFacingMode === 'environment') setCameraFacingMode('user');
        else setCameraFacingMode('environment');
    }

    /**
     * Restart the camera preview if the facing mode has changed
     * Also used to start it the first time 
     */
    useEffect(() => {
        stopCamera();
        (async() => {
            await startCamera();
        })();
    }, [cameraFacingMode]);

    /**
     * Take the picture and display it 
     */
    const takePicture = () => {
        const context = canvasRef.current!.getContext("2d");
        if(context) {
            const width = finalWidth;
            const height = (videoRef.current!.videoHeight / videoRef.current!.videoWidth) * width;

            if (width && height) {
                canvasRef.current!.width = width;
                canvasRef.current!.height = height;
                context.drawImage(videoRef.current!, 0, 0, width, height);
                setData(canvasRef.current!.toDataURL("image/png"));
                setDisplayPicture(true);
                stopCamera();
            }
        }
    };

    /**
     * When the user delete the taken picture
     * Start the camera again and delete the previously captured picture
     */
    const onDeletePicture = async () => {
        setDisplayPicture(false);
        setData("");
        await startCamera();
    }

    /**
     * When the user validate the picture, pass it to the parent
     */
    const onValidatePicture = () => {
        if(data) {
            props.onValidate(dataURLtoFile(data, `photo-${new Date().toISOString()}`));
        }
        stopCamera();
    }

    /**
     * When the user closes the component
     */
    const cancel = () => {
        stopCamera();
        props.onCancel();
    }

    return (
        <Box sx={{width: '100%', height: '100%', background: 'black'}} ref={containerRef}>
            <video ref={videoRef} style={{...CameraStyle.video, display: displayPicture ? 'none' : 'block'}}>Video stream not available.</video>

            <Box sx={CameraStyle.cancelContainer}>
                <IconButton onClick={cancel} sx={CameraStyle.iconButton}>
                    <ClearIcon sx={CameraStyle.icon} />
                </IconButton>
            </Box>


            {!displayPicture && <Box sx={CameraStyle.controlContainer}>
                    {hasFrontCamera &&  <IconButton onClick={switchCamera} sx={{...CameraStyle.iconButton, mr: 2}}>
                            <CameraswitchIcon sx={CameraStyle.icon} />
                        </IconButton>
                    }
                    <IconButton onClick={takePicture} sx={CameraStyle.iconButton}>
                        <PhotoCameraIcon sx={CameraStyle.icon} />
                    </IconButton>
            </Box>}

            <canvas ref={canvasRef} style={{display:'none'}}></canvas>

            <img ref={imgRef} style={{...CameraStyle.video, objectFit: 'contain', display: displayPicture ? 'block' : 'none'}} src={data} />

            {displayPicture && <Box sx={CameraStyle.controlContainer}>
                    <IconButton onClick={onDeletePicture} sx={{...CameraStyle.iconButton, mr: 2}}>
                        <DeleteIcon sx={CameraStyle.icon} />
                    </IconButton>
                    <IconButton onClick={onValidatePicture} sx={CameraStyle.iconButton}>
                        <DoneIcon sx={CameraStyle.icon} />
                    </IconButton>
            </Box>}


        </Box>
    );
}

export default CameraPhotoCapture;