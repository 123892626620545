import { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from "@app/hooks";
import Paths from '@app/routes';
import { BusinessError } from '@app/errors';

import { userSlice } from "@features/user/slices";

/**
 * 
 */
interface IFormInputs {
  username: string
  password: string
}

/**
 * 
 */
const initialValues: IFormInputs = {
  username: "",
  password: "",
};

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('signin');

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ globalError, setGlobalError ] = useState<string | null>(null);
  
  /**
   * 
   * @returns 
   */
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  /**
   * 
   * @param event 
   */
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /**
   * 
   */
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t('input_username_error_required')),
    password: Yup.string().required(t('input_password_error_required')),
  });

  /**
   * 
   */
  const { control, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: initialValues
  });

  /**
   * 
   * @param data 
   */
  const onSubmit = async (data: IFormInputs) => {
    const { username, password } = data;
    setLoading(true);
    setGlobalError(null);

    try {
      await dispatch(userSlice.signin(username, password));
      navigate(Paths.Authenticated.home);
    } catch (err){
      if(err instanceof BusinessError) {
        setGlobalError(err.message);
      }
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      component="form"
      noValidate
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 70px)"
    >
        <div style={{height:'1rem'}}>
          {globalError && <Typography color="error">{globalError}</Typography>}
        </div>

        <FormControl sx={{ m: 1, width: '25ch' }}>
          <InputLabel htmlFor="form-username">{t('input_username_label')}</InputLabel>
          <Controller
            name="username"
            control={control}
            render={({ field }) => <OutlinedInput {...field} 
              id="form-username"
              label={t('input_username_label')}
              aria-describedby="form-username-error-text" />} />
              <FormHelperText id="form-username-error-text">{errors.username?.message}</FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, width: '25ch' }} error={errors.password ? true : false}>
            <InputLabel htmlFor="form-password">{t('input_password_label')}</InputLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => <OutlinedInput {...field} 
                id="form-password"
                type={showPassword ? 'text' : 'password'}
                label={t('input_password_label')}
                aria-describedby="form-password-error-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment> } 
                />} />
                <FormHelperText id="form-password-error-text">{errors.password?.message}</FormHelperText>
            </FormControl>

          <LoadingButton loading={loading} variant="outlined" type="submit">{t('button_signin')}</LoadingButton>
    </Stack >
  );
}

export default SignIn;