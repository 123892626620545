import React, { useEffect } from "react";

import { useAppDispatch} from '@app/hooks';

import { commonSlice } from '@features/common/slices'

import useElementSize from '@helpers/useElementSize';

import { appBarHeight } from '@layouts/AuthenticatedLayout';

export const Content = ({ children }: { children : React.ReactNode | React.ReactNode[] }) => {
    const dispatch = useAppDispatch();
    const [circleContentRef, { width, height }] = useElementSize();
    
    useEffect(() => {
        dispatch(commonSlice.setContainerSize({
            width: width,
            height: height
        }));
      }, [height, width]);

    return (
        <div style={{
            width:'100%',
            height:`calc(100% - ${appBarHeight}px)`, 
            overflowX: 'hidden',
            
        }} 
        ref={circleContentRef}>
            {children}
        </div>
    );
}