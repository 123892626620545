
const tokenEntryName = "token";

export const storeToken = (token: string) => {
    localStorage.setItem(tokenEntryName, token);
}

export const getToken = (): string | null => {
    return localStorage.getItem(tokenEntryName);
}

export const deleteToken = () => {
    return localStorage.removeItem(tokenEntryName);
};

