
export interface User {
    id: string,
    email: string,
    username: string,
    picture: boolean,
    roles: Array<UserRole>
    privileges: Array<UserPrivilege>
}

export interface AuthenticationData {
    sessionId: string
}

export enum UserRole {
    USER = "USER",
    ADMIN = "ADMIN"
}

export enum UserPrivilege {
    POST_CREATION = "POST_CREATION",
    POST_CREATION_UPLOAD = "POST_CREATION_UPLOAD",
    POST_CREATION_INSTAGRAM = "POST_CREATION_INSTAGRAM",
    CIRCLE_CREATION = "CIRCLE_CREATION",
    CONTACT_SEARCH_ADD = "CONTACT_SEARCH_ADD",
    ADMIN_READ = "ADMIN_READ",
    ADMIN_WRITE = "ADMIN_WRITE"
}

export interface UserSettings {
    postDisplay: UserSettingsPostDisplay
}

export enum UserSettingsPostDisplay {
    SLIDESHOW = "SLIDESHOW",
    COLUMN = "COLUMN"
}


export enum NotificationCode {
    RECEIVED_CONTACT_INVITATION = "RECEIVED_CONTACT_INVITATION",
}

export enum NotificationType {
    INFO = "INFO",
    CONFIRM_ACTION = "CONFIRM_ACTION"
}

export enum NotificationActionType {
    CIRCLE_INVITATION = "CIRCLE_INVITATION",
    CONTACT_INVITATION = "CONTACT_INVITATION"
}

export enum NotificationActionResultType {
    ACCEPTED = "ACCEPTED",
    REFUSED = "REFUSED"
}

interface NotificationAction {
    type: NotificationActionType
    result ?: NotificationActionResultType
    resultDate ?: string
}

export interface Notification {
    id: string,
    creationDate: string,
    code: NotificationCode
    datas: Map<string, string>
    type: NotificationType
    readDate: string
    action ?: NotificationAction
}