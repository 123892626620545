import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Loading } from "@components/Loading";
import { useTranslation } from "react-i18next";

interface LazyScrollingContentProps<T> {
    height: string
    loadNext: () => void,
    items: Array<T> | null
    hasOlderItems: boolean
    noElementText: string
    render: (elements: Array<T>) => React.ReactNode | React.ReactNode[]
}

export const LazyScrollingContent = <T extends object>(props: LazyScrollingContentProps<T>) => {
    const { t } = useTranslation('common');
    const [loadNextPage, setLoadNextPage] = useState(false);
    const { items, hasOlderItems } = props;
    const containerRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if(items == null) {
            //setLoadNextPage(true);
            props.loadNext();
        }
    }, [items]);

    /**
     * 
     */
    useEffect(() => {
        if(containerRef && containerRef.current) {
            // the scrollbar is not visible yet, need to load more elements
            if(containerRef.current.scrollHeight <= containerRef.current.clientHeight) {
                setLoadNextPage(true);
            }
        }
    }, [items, containerRef]);

    /**
     * Load more notifs
     */
    useEffect(() => {
        if(!loadNextPage || !hasOlderItems) {
            setLoadNextPage(false);
            return;
        }
        (async () => {
            await props.loadNext(); // items is null means first load
            setLoadNextPage(false);
        })();
    }, [loadNextPage]);

    /**
     * 
     * @param event 
     * @returns 
     */
     const onScroll = (event: React.UIEvent<HTMLElement>) => {
        if (loadNextPage || !hasOlderItems) {
            return;
        }

        const scrollTop = event.currentTarget.scrollTop;
        const listHeight = (event.currentTarget.children[0] as HTMLElement).offsetHeight;
        if (scrollTop + event.currentTarget.offsetHeight > (listHeight - 10)) {
            setLoadNextPage(true);
        }
    }

    return (
        <>
            {items == null && <Loading />}
            {items && items.length === 0 && <Typography sx={{textAlign: 'center', my: 2}}>{props.noElementText}</Typography>}
            {items && items.length > 0 && 
                <Box sx={{
                    height: props.height,
                    width:'100%',
                    overflowY: 'auto'
                }}
                onScroll={onScroll}
                ref={containerRef}>
                    {props.render(items)}
                    {loadNextPage && <Typography sx={{textAlign: 'center', my: 2}}>{t('loading')}</Typography>}
                </Box>
            }
        </>
    );
};