import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import {reducer as circleReducer} from '@features/circle/slices/circleSlice'
import {reducer as circlePostReducer} from '@features/circle/slices/circlePostSlice'
import {reducer as circleUserReducer} from '@features/circle/slices/circleUserSlice'
import {reducer as postReducer} from '@features/post/slices/postSlice'
import {reducer as commonReducer} from '@features/common/slices/commonSlice'
import {reducer as contactReducer} from '@features/contact/slices/contactSlice'
import {reducer as userReducer, logoutActionType} from '@features/user/slices/userSlice'
import {reducer as notificationReducer } from '@features/user/slices/notificationSlice'

const appReducers = combineReducers({
  circle: circleReducer,
  circlePost: circlePostReducer,
  circleUser: circleUserReducer,
  post: postReducer,
  common: commonReducer,
  contact: contactReducer,
  user: userReducer,
  notification: notificationReducer
})

export type RootState = ReturnType<typeof appReducers>

const rootReducer = (state: RootState | undefined, action: AnyAction) => {   
  if(action.type === logoutActionType)
    return appReducers(undefined, action);
  else 
    return appReducers(state, action);
};


export default rootReducer
