import { Navigate, Outlet } from 'react-router-dom'
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from '@app/routes';

import { Loading } from '@components/Loading';

import { userSlice, userSelector } from '@features/user/slices';

const ProtectedRoutes = () => {
    const { user, settings, isLoggedIn } = useAppSelector(userSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(isLoggedIn && (user == null || settings == null)) {
            (async ()=> {
                try {
                    await dispatch(userSlice.loadCurrentUser());
                } catch(err) {}
            })();
        }
    }, []);

    if(!isLoggedIn) {
        return <Navigate to={Paths.Public.root} replace={true}/>;
    }

    return (user == null || settings == null) ? <Loading /> : <Outlet />;
}

export default ProtectedRoutes;