import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { Loading } from "@components/Loading";

import { CircleUsersList } from "@features/circle/components/CircleUsersList";
import { CircleUser, CircleUserRole } from "@features/circle/models";
import { circleSelector, circleUserSlice, circleUserSelector } from "@features/circle/slices";

import { Contact } from "@features/contact/models";
import { useConfirm } from "material-ui-confirm";

export const ManageCircleContacts = () => {
    const { t } = useTranslation('circle_user');
    const dispatch = useAppDispatch();
    const confirm = useConfirm();

    // final circle contact list
    const { users } = useAppSelector(circleUserSelector);
    const { circle } = useAppSelector(circleSelector);

    /**
     * 
     * @param users 
     */
    const addUsers = async (users: Array<Contact>) => {
        if(circle) {
            await dispatch(
                circleUserSlice.addUsers(circle.id, users.map(u => {return {
                    id: u.userId,
                    username: u.username,
                    picture: true,
                    role: CircleUserRole.READER
                }}))
            );
        }
    }

    /**
     * 
     * @param user 
     */
    const removeUser = async (user: CircleUser) => {
        if(circle) {
            try {
                await confirm({description: t('confirm_delete_user', {'username': user.username})});
                await dispatch(circleUserSlice.removeUser(circle.id, user));
            } catch(err) {}
        }
    };

    /**
     * 
     * @param user 
     */
    const updateUser = async (user: CircleUser) => {
        if(circle) {
            await dispatch(circleUserSlice.updateUser(circle.id, user));
        }
    };

    return (
        <>
            {users == null && <Loading />}
            {users != null && <CircleUsersList users={users} addUsers={addUsers} removeUser={removeUser} updateUser={updateUser} maxHeight='calc(100vh - 220px)'/>}
        </>
    )
}