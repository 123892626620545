import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Button, FormControl, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";

import { useAppDispatch } from "@app/hooks";
import Paths from "@app/routes"

import { successChannel } from '@channels/globalNotificationChannel'

import { Header } from "@components/Header";
import { Content } from "@components/Content";
import { Loading } from "@components/Loading";

import circlePostSlice from '@features/circle/slices/circlePostSlice';

import { PostViewer } from "@features/post/components/PostViewer";
import { PostCircleOverview, Post } from "@features/post/models";
import { postService } from '@features/post/services';
import { postSlice } from '@features/post/slices';

/**
 * 
 */
interface IFormInputs {
    description: string  | null
}

export const ViewPost = () => {
    const { t } = useTranslation('post');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const confirm = useConfirm();
    let { postId } = useParams();
    const [post, setPost] = useState<Post>();
    const [circles, setCircles] = useState<Array<PostCircleOverview>>();
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);


    useEffect(() => { 
        if(postId) {
            (async () => {
                const loadedPost = await postService.getById(postId);
                setPost(loadedPost);
                const loadedCircles = await postService.listCirclesUsingPost(postId);
                setCircles(loadedCircles);
            })();
        }
    }, [postId]);
    
    const onBack = () => {
        navigate(Paths.Authenticated.posts);
    }

    /**
     * 
     */
     const validationSchema = Yup.object({
        description: Yup.string().nullable().defined()
     });

    /**
     * 
     */
     const { control, reset, handleSubmit } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: useMemo(() => {
          return {
            description: post?.description ? post.description : null
          };
        }, [post])
      });

    useEffect(() => {
        reset(post);
    }, [post]);


    /**
     * 
     * @param data 
     */
     const onSubmit = async (data: IFormInputs) => {
        if(post) {
            setLoadingUpdate(true);
            await dispatch(postSlice.updatePost(post.id, data.description ? data.description : ''));
            successChannel.emit(t('notification_update_success'));
            navigate(Paths.Authenticated.posts);
        }
    }

    /**
     * 
     * @param data 
     */
     const deletePost = async () => {
        if(post) {
            try {
                await confirm({description: t('confirm_delete_post_content')});
                await dispatch(postSlice.deletePost(post.id));
                successChannel.emit(t('notification_delete_success'));
                navigate(Paths.Authenticated.posts);
            } catch(err){}
        }
    }

    /**
     * 
     */
    const deletePostFromCircle = async (circle: PostCircleOverview) => {
        if(post) {
            try {
                await confirm({description: t('confirm_delete_circle_post_content')});
                await dispatch(circlePostSlice.removeCircleFromPost(circle.id, post));
                if(circles) {
                    setCircles(circles.filter(c => c.id !== circle.id));
                }
            } catch(err){}
        }
    };

    if(!post) {
        return (
            <>
                <Header onBack={onBack}>
                    <Skeleton variant="rectangular" sx={{mb: 1, width: '100%'}}/>
                </Header>
                <Content>
                    <Loading />
                </Content>
            </>
        );
    }

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2" sx={{whiteSpace: 'nowrap', width:'100%', overflow:'hidden',textOverflow: 'ellipsis'}}>{t('post_title', {"description": post.description})}</Typography>
            </Header>
            <Content>
                <PostViewer post={post} />
                <Box sx={{p: 2}} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>

                    <FormControl sx={{width: '100%'}}>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => <TextField {...field}
                                id="form-description"
                                multiline
                                label={t('input_description_label')}
                                rows={4} />
                            } />
                    </FormControl>
                    
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', mt: 2, gap: 1}}>
                        <LoadingButton loading={loadingUpdate} variant="outlined" color="primary" type="submit">
                            {t('submit_update')}
                        </LoadingButton>
                        <Button variant="outlined" color="error" onClick={deletePost}>
                            {t('submit_delete')}
                        </Button>
                    </Box>
                </Box>

                <Box sx={{p: 2}}>
                    <Typography variant="h3">{t('circle_using_post')}</Typography>
                    {!circles && <Loading />}

                    {circles && circles.length === 0 && 
                        <Typography sx={{textAlign: 'center', my: 2}}>{t('no_circle_using_post')}</Typography>
                    }

                    {circles && circles.length > 0 && 
                        <List sx={{ width: '100%' }}>
                            {circles.map(circle => 
                                <ListItem key={circle.id} 
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => deletePostFromCircle(circle)}>
                                            <DeleteIcon color="error"/>
                                        </IconButton>
                                    }>
                                    <ListItemAvatar>
                                        <Avatar>C</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={circle.name} secondary={t('nb_users', {nb: circle.nbUsers})} />
                                </ListItem>
                            )}
                        </List>
                    } 
                </Box>
            </Content>
        </>
    );
}

export default ViewPost;