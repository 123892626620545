import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@app/store';
import App from '@app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import '@app/i18n';
import '@app/axios/config.ts'

const container = document.getElementById('root')!;
const root = createRoot(container);

// Note: strict mode will render component twice to detect some issues on dev
// <React.StrictMode></React.StrictMode>
root.render(
  
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
