import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormHelperText, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { debounce } from '@mui/material/utils';
import SendIcon from '@mui/icons-material/Send';
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

import { useAppDispatch } from "@app/hooks";
import Paths from "@app/routes";

import { Content } from "@components/Content"
import { Header } from "@components/Header"

import { ContactInvitationStatus, SearchContact } from "@features/contact/models";
import { contactService, contactInvitationService } from '@features/contact/services';
import { contactSlice } from "@features/contact/slices";

interface SearchContactWithResult extends SearchContact {
    result ?: ContactInvitationStatus
}

export const AddContact = () => {
    const { t } = useTranslation('add_contact');
    const { t: ct } = useTranslation('common');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inputValue, setInputValue] = React.useState('');
    const [loading, setLoading] = useState((false));
    const [loadingAddContact, setLoadingAddContact] = useState<string | null>(null);
    const [contacts, setContacts] = useState<Array<SearchContactWithResult> | null>(null);
    const [openSendInvitationDialog, setOpenSendInvitationDialog] = useState<boolean>(false);
    const [invitationEmail, setInvitationEmail] = React.useState('');
    const [invitationEmailError, setInvitationEmailError] = React.useState('');

    const onBack = () => {
        navigate(Paths.Authenticated.manageContacts);
    }

    /**
     * 
     */
    const fetch = React.useMemo(
        () =>
            debounce(
                async (
                    request: { input: string },
                    callback: (result: Array<SearchContact>) => void,
                ) => {
                    setLoading(true);
                    const result = await contactService.searchContact(request.input);
                    callback(result);
                },
                1000,
            ),
        [],
    );
    
    /**
     * 
     */
    React.useEffect(() => {
        if (inputValue === '' || inputValue.length < 3) {
            setContacts(null);
            return;
        }

        
        fetch({ input: inputValue }, (result: Array<SearchContact>) => {
            var updatedResult = result.map(r => {
                return {...r, result: r.pendingInvitation ? ContactInvitationStatus.PENDING : undefined}
            });
            setContacts([...updatedResult]);
            setLoading(false);
        });
    }, [inputValue, fetch]);

    /**
     * 
     * @param contact 
     */
    const addContact = async (contact: SearchContact) => {
        setLoadingAddContact(contact.userId);
        const invitation = await dispatch(contactSlice.addContact(contact));
        if(contacts) {
            setContacts(contacts.map(c => c.userId !== contact.userId ? c : {...c, canBeAdded: false, result: invitation.status}));
        }
        setLoadingAddContact(null);
    };

    const schema = Yup.object().shape({
        email: Yup.string().required('Champ obligatoire').email('Pas un email'),
    });

    /**
     * 
     */
    const sendInvitation = async () => {
        try {
            await schema.validate({email: invitationEmail});
            await contactInvitationService.sendExternalInvitation(invitationEmail);
            setOpenSendInvitationDialog(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                setInvitationEmailError(error.message);
            }
        }
    };

    /**
     * 
     */
    const handleOpenSendInvitationDialog = () => {
        setInvitationEmail('');
        setInvitationEmailError('');
        setOpenSendInvitationDialog(true);
    };

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2" noWrap>{t('page_title')}</Typography>
            </Header>
            <Content>
                <Box sx={{ p: 2 }}>
                    <FormControl sx={{width: '100%'}}>
                        <InputLabel htmlFor="input_search">{t('input_search')}</InputLabel>
                        <OutlinedInput
                            id="input_search"
                            label={t('input_search')}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                            }} 
                            endAdornment={
                            <InputAdornment position="end">
                                    {loading && <CircularProgress />}
                                </InputAdornment>
                            }/>
                    </FormControl>

                    {contacts && inputValue !== '' && inputValue.length >= 3 && (
                        (contacts.length === 0  && <Typography sx={{ textAlign: 'center', my: 2 }}>{t('no_contact_found')}</Typography>)
                        || 
                    (contacts.length > 0 &&
                            <List sx={{ width: '100%' }}>
                                {contacts.map(contact =>
                                    <ListItem key={contact.userId}>
                                        <ListItemAvatar>
                                            <Avatar>C</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={contact.username} />

                                        {contact.canBeAdded && 
                                            <LoadingButton loading={loadingAddContact === contact.userId} variant="outlined" onClick={() => addContact(contact)}>{t('add_button')}</LoadingButton>
                                        }
                                        {!contact.canBeAdded && contact.result &&
                                            <Button variant="outlined" disabled={true}>{t(`contact_${contact.result}`)}</Button>
                                        }
                                    </ListItem>
                                )}
                            </List>
                        )
                    )}
                </Box>

                <Fab color="secondary" aria-label="add" style={{
                    position: 'fixed',
                    top: 'calc(100% - 70px)',
                    left: 'calc(100% - 70px)'
                }} onClick={handleOpenSendInvitationDialog}>
                    <SendIcon />
                </Fab>
            </Content>

            <Dialog
                open={openSendInvitationDialog}
                onClose={() => setOpenSendInvitationDialog(false)}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        minWidth: "70%",  // Set your width here
                      },
                    },
                  }}>
                <DialogTitle>{t('dialog_send_invitation_title')}</DialogTitle>
                <DialogContent>
                    <FormControl sx={{width: '100%', mt: 2}}>
                        <InputLabel htmlFor="input_invitation_email">{t('input_invitation_email')}</InputLabel>
                        <OutlinedInput
                            id="input_invitation_email"
                            label={t('input_invitation_email')}
                            onChange={(e) => {
                                setInvitationEmailError('');
                                setInvitationEmail(e.target.value);
                            }} />
                        <FormHelperText sx={{color: "error.main"}}>{invitationEmailError}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendInvitation}>{t('send_button')}</Button>
                    <Button onClick={() => setOpenSendInvitationDialog(false)}>{ct('cancel_button')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}