import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontFamily: 'monospace',
      fontWeight: 700,
      letterSpacing: '.3rem',
    },
    h2: {
      fontSize: '1.3rem',
      fontWeight: 600,
      lineHeight: 1.334,
      letterSpacing: '0em'
    },
    h3: {
      fontSize: '1.2rem', 
      fontWeight: 400,
    },
  },
  palette: {
    primary :{
      main: '#54c1e0'
    },
    secondary: {
      main: '#dd8fd1'
    }
  }
});

/*
primary?: PaletteColorOptions;
  secondary?: Palette:ColorOptions;
  error?: PaletteColorOptions;
  warning?: PaletteColorOptions;
  info?: PaletteColorOptions;
  success?: PaletteColorOptions;
  mode?: PaletteMode;
  tonalOffset?: PaletteTonalOffset;
  contrastThreshold?: number;
  common?: Partial<CommonColors>;
  grey?: ColorPartial;
  text?: Partial<TypeText>;
  divider?: string;
  action?: Partial<TypeAction>;
  background?: Partial<TypeBackground>;
  getContrastText?: (background: string) => string;*/