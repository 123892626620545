import { useAppDispatch, useAppSelector } from "@app/hooks";

import { InstagramPostSelection } from "@features/post/instagram/components/InstagramPostSelection";
import { circlePostSlice, circleSelector } from '@features/circle/slices';
import { Post } from "@features/post/models";

export const SelectCircleInstagramPost = () => {
    const { circle } = useAppSelector(circleSelector);
    const dispatch = useAppDispatch();
    
    const submitSteps = [
        {
            process: async (post: Post) => {
                await dispatch(circlePostSlice.addPost(circle!.id, post));
            }
        }
    ];

    return (
        <>
            <InstagramPostSelection additionalSubmitSteps={submitSteps}/>
        </>
    );
}