import { useEffect, useState } from "react";
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { Content } from "@components/Content"
import { Header } from "@components/Header"
import { LazyScrollingContent } from "@components/LazyScrollingContent";

import { commonSlice } from "@features/common/slices";

import { Notification, NotificationActionResultType, NotificationType } from '@features/user/models';
import notificationHandlers from '@features/user/models/notificationHandlers';
import { notificationService }  from '@features/user/services';
import { notificationSlice, notificationSelector } from '@features/user/slices';
import useTranslate from "@hooks/useTranslate";

export const Notifications = () => {
    const { t } = useTranslate('notifications');

    const dispatch = useAppDispatch();
    const { unread } = useAppSelector(notificationSelector);
    const [loadingPage, setLoadingPage] = useState<number>(0);
    const [notifications, setNotifications] = useState<Array<Notification> | null>(null);
    const [hasMoreNotifications, setHasMoreNotifications] = useState<boolean>(true);

    // force to set to 0 because the load of unread notifications is done at AuthenticatedLayout level
    // which means it's loaded async and we need to them to read on this page
    // this listener is used only because if the user is refreshing this page, we need to be sure the load is over before
    // setting them to read
    useEffect(() => {
        if (unread > 0) {
            dispatch(notificationSlice.markAllRead());
        }
    }, [unread]);

    /**
     * 
     * @param loadingPage 
     * @returns 
     */
    const loadNotifications = async () => {
        const {notifications: data, hasNext} = await notificationService.loadNotifications(loadingPage);
        setLoadingPage(loadingPage + 1);
        setHasMoreNotifications(hasNext);
        
        if(notifications) {
            setNotifications(notifications.concat(data));
        } else {
            setNotifications(data);
        }
    };

    const onBack=() => {
        dispatch(commonSlice.openDrawer());
        setNotifications(null);
    }

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2" noWrap>{t('page_title')}</Typography>
            </Header>
            <Content>
                <LazyScrollingContent height='100%' items={notifications} hasOlderItems={hasMoreNotifications} loadNext={loadNotifications} noElementText={t('no_notifications')} 
                    render={(notifications: Array<Notification>) => 
                    <List>
                        {notifications.map(notif => {
                            switch(notif.type) {
                                case NotificationType.INFO: return <InfoNotification notification={notif} key={notif.id} />;
                                case NotificationType.CONFIRM_ACTION: return <ConfirmActionNotification notification={notif} key={notif.id} />;
                            }
                        })}
                    </List>
                } />
            </Content>
        </>
    )
}

interface NotificationProps {
    notification: Notification
}

/**
 * 
 * @param props 
 * @returns 
 */
const InfoNotification = (props: NotificationProps) => {
    const { notification } = props;
    const { t } = useTranslate('notifications');
    const navigate = useNavigate();

    const handler = notificationHandlers.get(notification.code);

    const onClick = (notification: Notification) => {
        if(handler && handler.targetRoute) {
            navigate(handler.targetRoute);
        }
    }

    return (
        <ListItem sx={{
            cursor: handler && handler.targetRoute ? 'pointer' : 'auto'
        }}>
            <ListItemAvatar>
                <Avatar>
                    <NotificationsIcon />
                </Avatar>
            </ListItemAvatar>

            <ListItemText onClick={() => onClick(notification)}
                primary={t(`notif_${notification.code}_title`, notification.datas)}
                secondary={t(`notif_${notification.code}_message`, notification.datas)}
            />
        </ListItem>
    );
};

/**
 * 
 * @param props 
 * @returns 
 */
const ConfirmActionNotification = (props: NotificationProps) => {
    const { notification } = props;
    const { t : ct} = useTranslate('common');
    const { t } = useTranslate('notifications');
    const dispatch = useAppDispatch();
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [loadingRefuse, setLoadingRefuse] = useState(false);

    const accept = async () => {
        if(notification.action) {
            setLoadingAccept(true);
            await dispatch(notificationSlice.acceptNotification(notification));
            notification.action = {...notification.action, result: NotificationActionResultType.ACCEPTED};
            setLoadingAccept(false);
        }
    }

    const refuse = async () => {
        if(notification.action) {
            setLoadingRefuse(true);
            await dispatch(notificationSlice.refuseNotification(notification));
            notification.action = {...notification.action, result: NotificationActionResultType.REFUSED};
            setLoadingRefuse(false);
        }
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <NotificationsIcon />
                </Avatar>
            </ListItemAvatar>

            <ListItemText
                primary={t(`notif_${notification.code}_title`, notification.datas)}
                secondary={t(`notif_${notification.code}_message`, notification.datas)}
            />

            {notification.action && (
                (notification.action.result && <>
                    <Button variant="outlined" disabled={true}>{t(`notif_${notification.action.result}`)}</Button>
                </>)
                ||
                (!notification.action.result && <>
                    <LoadingButton loading={loadingAccept} disabled={loadingRefuse} variant="outlined" sx={{mr: 1}} onClick={accept}>{ct('accept_button')}</LoadingButton>
                    <LoadingButton loading={loadingRefuse} disabled={loadingAccept} variant="outlined" color="error" onClick={refuse}>{ct('refuse_button')}</LoadingButton>
                </>)
            )}
        </ListItem>
    );
};