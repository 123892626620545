
import React, { useRef, useState } from "react";
import useInterval from "@hooks/useInterval";

type Fn<T> = (
  e: React.MouseEvent<T, MouseEvent> | React.TouchEvent<T>
) => void;

type MouseEvt<T> = React.MouseEvent<T, MouseEvent> | React.TouchEvent<T>;

type Opts<T extends HTMLElement> = {
  shouldPreventDefault?: boolean;
  delay?: number;
  onClick?: boolean | Fn<T>;
};

/**
 * 
 * @param event 
 * @returns 
 */
const assertTouchEvt = (event: Event | TouchEvent): event is TouchEvent => {
    return "touches" in event;
};

/**
 * 
 * @param event 
 * @returns 
 */
const preventDefault = (event: Event | TouchEvent) => {
    if (!assertTouchEvt(event)) return;
  
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  };

/**
 * 
 * @param onLongPress 
 * @param opts 
 * @returns 
 */
export default function useLongPress<T extends HTMLElement>(onLongPress: Fn<T>, opts: Opts<T> = {}) {
  const {
    // default onClick to onLongPress if no onClick fn is provided
    onClick = onLongPress,
    delay: initialDelay = 300,
  } = opts;

  const timerRef = useRef<number | null>(null);
  const action = useRef<string | null>(null);
  const isLongPress = useRef<boolean>(false);
  const target = React.useRef<EventTarget | null>(null);

    /**
     * 
     * @param event 
     */
  const startPressTimer = (event: MouseEvt<T>) => {
    event.target.addEventListener("touchend", preventDefault, {
        passive: false,
    });
    target.current = event.target;

    action.current = 'click';
    isLongPress.current = false;
    timerRef.current = window.setTimeout(() => {
        action.current = 'longpress';
        isLongPress.current = true;
    }, initialDelay);
  }

  const stopPress = (e: MouseEvt<T>) => {
    clearTimer();

    if(action.current) {
        if(action.current === 'longpress'){
            onLongPress(e);
        } else {
            if(typeof onClick === "boolean" && onClick) {
                onLongPress(e)
            } else {
                (onClick as Fn<T>)(e);
            }
        }
    }
    action.current = null;
    isLongPress.current = false;

    if (target.current) {
        target.current.removeEventListener("touchend", preventDefault);
        target.current = null
    }
  }

  const clearTimer = () => {
    if(timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
    }
  };

  const handleOnMouseDown = (e: React.MouseEvent<T, MouseEvent>) => {
    if(isLongPress.current) {
        return;
    }
    startPressTimer(e);
  }

  const handleOnTouchStart = (e: React.TouchEvent<T>) => {
    startPressTimer(e);
  }

  const handleOnTouchEnd = (e: React.TouchEvent<T>) => {
    stopPress(e);
  }

  const handleOnMouseUp = (e: React.MouseEvent<T, MouseEvent>) => {
    stopPress(e);
  }

  const handleOnMouseLeave = () => {
    clearTimer();
  };

  return {
    onMouseDown: (e: React.MouseEvent<T, MouseEvent>) => handleOnMouseDown(e),
    onMouseUp: (e: React.MouseEvent<T, MouseEvent>) => handleOnMouseUp(e),
    onMouseLeave: (e: React.MouseEvent<T, MouseEvent>) => handleOnMouseLeave(),
    onTouchStart: (e: React.TouchEvent<T>) => handleOnTouchStart(e),
    onTouchEnd: (e: React.TouchEvent<T>) => handleOnTouchEnd(e),
  };
};