import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction, AppThunkDispatch } from "@app/hooks";
import circleService from '@features/circle/services/circleService';
import { Circle, CircleAuthorization, CircleOverview, CircleSettings, CircleUser } from '@features/circle/models';
import postSlice from '@features/post/slices/postSlice';
import circlePostSlice from "@features/circle/slices/circlePostSlice";
import circleUserSlice from "@features/circle/slices/circleUserSlice";
import circleUserService from '@features/circle/services/circleUserService';
import { CircleTab, CircleTabType } from "@features/circle/models/circleNavigation";
import commonSlice from '@features/common/slices/commonSlice';


export interface CircleState {
  selectedTab: CircleTabType,
  loadingCircle: boolean,
  circle: Circle | null,
  circles: Array<CircleOverview> | null,
}

const initialState: CircleState = {
  selectedTab: CircleTabType.POSTS,
  loadingCircle: false,
  circle: null,
  circles: null,
};


const circleSlice = createSlice({
  name: "circle",
  initialState,
  reducers: {
    markAllRead: (state, action: PayloadAction<string>) => {
      if(state.circles) {
        state.circles!.find(c => c.id === action.payload)!.unread = 0;
      }
    },
    loadCircle: (state, action: PayloadAction<Circle>) => {
      state.circle = {
        ...action.payload
      };
      state.loadingCircle = false;
    },
    loadCircles: (state, action: PayloadAction<Array<CircleOverview>>) => {
      state.circles = action.payload;
    },
    loadInProgress: (state) => {
      state.circle = null;
      state.loadingCircle = true;
    },
    selectTab: (state, action: PayloadAction<CircleTab>) => {
      state.selectedTab = action.payload.type;
    },
    addCircleToList: (state, action: PayloadAction<Circle>) => {
      const circle = action.payload;
      const circleOverview: CircleOverview = {
        id: circle.id,
        name: circle.name,
        unread: 0
      };
      if(state.circles) {
        state.circles = [circleOverview, ...state.circles];
      } else {
        state.circles = [circleOverview];
      }
    },
    updateCircle: (state, action: PayloadAction<Circle>) => {
      state.circle = action.payload;
      const circleOverview = state.circles?.find(c => c.id === action.payload.id);
      if(circleOverview) {
        circleOverview.name = action.payload.name;
      }
    },
    updateCircleSettings: (state, action: PayloadAction<Circle>) => {
      state.circle = action.payload;
    },
    removeCircle: (state, action: PayloadAction<string>): CircleState => {
      return {
        ...initialState,
        circles: state.circles ? state.circles.filter(c => c.id !== action.payload) : null
      }
    }
  },
});

const prepareCircleLoading = async (dispatch: AppThunkDispatch) => {
  await dispatch(circleSlice.actions.loadInProgress());
  await dispatch(circlePostSlice.resetCirclePostsState());
  await dispatch(circleUserSlice.resetCircleUserState());
  await dispatch(postSlice.resetPostsState());
};

/**
 * 
 * @param username 
 * @param password 
 * @returns 
 */
const selectCircle = (circleId: string): AppThunkAction => {
  return async (dispatch, getState) => {

    const { circle } = getState().circle;

    await dispatch(commonSlice.closeDrawer());

    if(circle == null || circleId !== circle.id) {
      await prepareCircleLoading(dispatch);
      const circle = await circleService.findCircleById(circleId);
      await circleUserService.markAsRead(circleId);
      await dispatch(circleSlice.actions.markAllRead(circleId));
      await dispatch(circleSlice.actions.loadCircle(circle));

      if(circle.authorizations.includes(CircleAuthorization.SHOW_TITLE_USERS) ||
        circle.authorizations.includes(CircleAuthorization.SHOW_ALL_USERS)) {
          await dispatch(circleUserSlice.loadUsers(circleId));
      }
    }
  };
};

/**
 * 
 * @returns 
 */
const loadCircles = (): AppThunkAction => {
  return async (dispatch) => {
    const circles = await circleService.findCircles();
    await dispatch(circleSlice.actions.loadCircles(circles));
  }
};

/**
 * 
 */
const createCircle = (name: string, users: Array<CircleUser>, settings: CircleSettings, icon ?: File): AppThunkAction<Promise<Circle>> => {
  return async (dispatch) => {
    const circle = await circleService.createCircle(name, settings, icon);
    if(users.length > 0) {
      await circleUserService.addUsers(circle.id, users);
    }
    await dispatch(circleSlice.actions.addCircleToList(circle));

    await prepareCircleLoading(dispatch);
    await dispatch(circleSlice.actions.loadCircle(circle));
    return circle;
  };
};

/**
 * 
 * @param circleId 
 * @param name 
 * @param settings 
 */
const updateCircle = (circleId: string, name: string, icon ?: File): AppThunkAction => {
  return async(dispatch) => {
    const circle = await circleService.updateCircle(circleId, name, icon);
    await dispatch(circleSlice.actions.updateCircle(circle));
  };
};

/**
 * 
 * @param circleId 
 * @param name 
 * @param settings 
 */
 const updateCircleSettings = (circleId: string, settings: CircleSettings): AppThunkAction => {
  return async(dispatch) => {
    const circle = await circleService.updateCircleSettings(circleId, settings);
    await dispatch(circleSlice.actions.updateCircleSettings(circle));
  };
};

/**
 * 
 * @param circleId 
 */
const leaveCircle = (circleId: string): AppThunkAction => {
  return async(dispatch) => {
    await circleUserService.leaveCircle(circleId);
    await dispatch(circleSlice.actions.removeCircle(circleId));
  };
};

export const circleSelector = (state: { circle: CircleState }) => state.circle;

export const reducer = circleSlice.reducer;

const slice = {
  loadCircles,
  selectCircle,
  createCircle,
  leaveCircle,
  updateCircle,
  updateCircleSettings,
  selectTab: circleSlice.actions.selectTab,
};

export default slice;