import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Size {
    width: number,
    height: number
  }

export interface CommonState {
    openDrawer: boolean,
    containerSize: Size
}

const initialState: CommonState = {
    openDrawer: false,
    containerSize: {
        height: 0,
        width: 0
    }
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    openDrawer: (state) => {
        state.openDrawer = true;
    },
    closeDrawer: (state) => {
      state.openDrawer = false;
    },
    setContainerSize: (state, action: PayloadAction<Size>) => {
        state.containerSize = action.payload;
    },
  },
});

export const commonSelector = (state: { common: CommonState }) => state.common;

export const reducer = commonSlice.reducer;

const slice = {
  openDrawer: commonSlice.actions.openDrawer, 
  closeDrawer: commonSlice.actions.closeDrawer,
  setContainerSize: commonSlice.actions.setContainerSize, 
};

export default slice;