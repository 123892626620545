import { useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { commonSelector } from '@features/common/slices/commonSlice'

import { ThumbnailPost } from "@features/post/components/ThumbnailPost";
import { defaultThumbnailPostWidth, computeThumbnailPostSize } from '@features/post/helpers/post.helper';
import { Post, SelectablePost } from "@features/post/models";
import { postService } from '@features/post/services'


import { circlePostSlice, circleSelector } from "@features/circle/slices";
import { LazyScrollingContent } from "@components/LazyScrollingContent";
import { PostCreationMenu } from "@features/post/components/PostCreationMenu";



export const AddCirclePost = () => {
    const { t } = useTranslation('circle_post');
    const dispatch = useAppDispatch();
    const { circle } = useAppSelector(circleSelector);

    const { containerSize } = useAppSelector(commonSelector);
    const [postSize, setPostSize] = useState<number>(defaultThumbnailPostWidth);

    const [ posts, setPosts ] = useState<Array<SelectablePost> | null>(null);
    const [ hasOlderItems, setHasOlderItems ] = useState<boolean>(true);
    const [ loadingPage, setLoadingPage ] = useState<number>(0);

    /**
     * 
     */
    useEffect(() => {
        setPostSize(computeThumbnailPostSize(containerSize).postSize);
    }, [containerSize]);

    /**
     * 
     * @param post 
     */
    const onPostClick = async (post: Post) => {
        if(posts && circle) {
            const workingPost = posts.find(p => p.id === post.id);
            if(workingPost) {
                if(workingPost.selected) {
                    await dispatch(circlePostSlice.removePostFromCircle(circle.id, post.id));
                } else {
                    await dispatch(circlePostSlice.addPost(circle.id, post));
                }

                setPosts(
                    posts.map(p => {
                        if(p.id === post.id) {
                            return {
                                ...p,
                                selected: !p.selected
                            };   
                        } else {
                            return p;
                        }
                    })
                );
            }
        }
    };

    /**
     * 
     */
    const loadPosts = async () => {
        if(circle) {
            const data = await postService.loadPostsForCircle(loadingPage, circle.id);
            if(loadingPage === 0) {
                setPosts(data.posts);
            } else {
                if(posts == null) {
                    setPosts(data.posts);
                } else {
                    setPosts(posts.concat(data.posts));
                }
            }

            setHasOlderItems(data.hasNext);
            setLoadingPage(loadingPage + 1);
        }
    };

    return (
        <>
            <LazyScrollingContent height='100%' loadNext={loadPosts} items={posts} hasOlderItems={hasOlderItems} noElementText={t('no_post')}
                render={(posts: Array<SelectablePost>) => 
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                    {posts.map((post) => (
                        <ThumbnailPost post={post} size={postSize} key={post.id} onClick={onPostClick} checked={post.selected} selectable={post.selected}/>
                    ))}
                </div>
            }/>
            
            <PostCreationMenu 
                displayTooltip={posts != null && posts.length === 0}
                instaPath={generatePath(Paths.Authenticated.circleSelectInstagramPost, {'circleId': circle!.id})}
                uploadPath={generatePath(Paths.Authenticated.circleCreatePost, {'circleId': circle!.id})}
            />
        </>
    )
}