import axios from '@app/axios';
import { buildQueryParams } from '@helpers/http.helper';
import { CirclePost } from '@features/circle/models';

export enum LoadOrder {
    OLDER = 'OLDER',
    MORE_RECENT = 'MORE_RECENT'
}

interface LoadPostsData {
    hasMoreRecentPosts: boolean
    hasOlderPosts: boolean
    posts: Array<CirclePost>
}

/**
 * 
 * @param circleId 
 * @param nb 
 */
const loadLastPosts = async (circleId: string, nb ?: number): Promise<LoadPostsData> => {
    const queryParams = buildQueryParams({
        nb
    });
    const { data } = await axios.get(`/circles/${circleId}/posts/last${queryParams}`);
    return data;
};

/**
 * 
 * @param circleId 
 * @returns 
 */
const loadPosts = async (circleId: string, lastPostId ?: string, loadOrder ?: LoadOrder, nb ?: number): Promise<LoadPostsData> => {
    const queryParams = buildQueryParams({
        from: lastPostId,
        order: loadOrder,
        nb: nb
    });
    const { data } = await axios.get(`/circles/${circleId}/posts${queryParams}`);
    return data;
};

/**
 * 
 * @param circleId 
 * @param postId 
 * @returns 
 */
const addPost = async (circleId: string, postId: string) => {
    await axios.post(`/circles/${circleId}/posts`, {
        postId
    });
};

/**
 * 
 * @param circleId 
 * @param postId 
 */
const removePost = async (circleId: string, postId: string) => {
    await axios.delete(`/circles/${circleId}/posts/${postId}`);
};


const slice = {
    loadLastPosts,
    loadPosts,
    addPost,
    removePost
};

export default slice;