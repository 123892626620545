const config = {
    apiRoot: '/api',
    fileUploadPictureMaxSize: '5MB',
    fileUploadVideoMaxSize: '10MB',
    featureToggle: {
        user: {
            signup: false
        }
    }
};

export default config;