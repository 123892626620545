import React, { ForwardedRef, forwardRef, useRef } from 'react';
import PostSettingIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Typography } from '@mui/material';
import ReactTimeago from 'react-timeago';

import { useAppDispatch} from '@app/hooks';

import { CirclePost } from '@features/circle/models';
import { circlePostSlice } from '@features/circle/slices';

import { PostViewer } from '@features/post/components/PostViewer';
import { UserAvatar } from '@components/icons/UserAvatar';


interface CirclePostProps {
    post: CirclePost,
    onOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    displayMenu: boolean
}

type PropsType = React.RefAttributes<HTMLElement> & CirclePostProps;

export const CirclePostViewer: React.FunctionComponent<PropsType> = forwardRef((props: CirclePostProps, fowardedRef: ForwardedRef<HTMLElement>) => {
  const dispatch = useAppDispatch();
  const postTitleRef = useRef<HTMLElement | null>(null)

  const post = props.post;
  
  const onFocused = (scrollTopValue: number) => {
    if(postTitleRef && postTitleRef.current) {
      scrollTopValue = scrollTopValue - postTitleRef.current.offsetHeight
    }
    dispatch(circlePostSlice.scrollTop(scrollTopValue));
  }

  return (
    <Box sx={{mb: 1, background:'#000'}} >
      <Box
        ref={postTitleRef}
        sx={{
          width: '100%',
          minHeight: '40px',
          background: 'black',
          px: 2,
          pt: 1,
          color: '#f1f1f1',
          display: 'flex',
          alignItems: 'center',
          mb: 1
        }}>
          <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <UserAvatar user={post.user} sx={{ mr: 1, width: 24, height: 24 }}/>
            <Typography>
              {post.user.username}
            </Typography>
          </Box>
          <Box>
            {props.displayMenu &&
              <IconButton sx={{flexShrink: 0, color: '#f1f1f1'}} onClick={props.onOpenMenu}>
                <PostSettingIcon />
              </IconButton>
            }
          </Box>
      </Box>

      <PostViewer post={post} focused={post.focused} onFocused={onFocused} ref={fowardedRef}/>

      <Box
          sx={{
            width: '100%',
            minHeight: '40px',
            background: 'black',
            mt: 1,
            px: 2,
            pb: 1,
            color: '#f1f1f1'
          }}>
            <ReactTimeago date={post.date} live={false} style={{fontSize: '0.75rem'}}/>
            <Typography>
              {post.description}
            </Typography>
        </Box>
    </Box>
  );
});