import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import { Box, Button, IconButton, Typography, FormControl, LinearProgress, TextField, Dialog } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideocamIcon from '@mui/icons-material/Videocam';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import config from '@app/config';
import { useAppDispatch } from "@app/hooks";

import { SelectGalleryPictureButton } from '@components/SelectGalleryPictureButton';

import { Post, VideoMetadata } from "@features/post/models";
import { postService } from '@features/post/services';
import { postSlice } from '@features/post/slices'

import { convertToByteSize, convertToReadableSize } from "@helpers/size.helper";
import CameraPhotoCapture from './CameraPhotoCapture';
import { generateVideoThumbnail, generatePictureThumbnail, getVideoMetadata, dataURLtoFile, consolidateVideoMetadata } from '@helpers/file.helper';
import { formatduration } from '@helpers/date.helper'
import CameraVideoCapture, { StreamSettings } from './CameraVideoCapture';
import useCameraDevice from '@hooks/useCameraDevice';


interface CreatePostProps {
    additionalSubmitSteps?: Array<{
        label: string,
        process: (post: Post) => void
    }>
    navigate: (postId: Post) => void
}

/**
 * 
 */
enum FileType { PICTURE, VIDEO }

interface Media {
    key: string,
    mediaSource ?: string,
    file: Blob,
    thumbnailUrl: string,
    type: FileType,
    metadata ?: VideoMetadata,
}

function mediaKeyGenerator(): string {
    return 'mediaid-' + Date.now();
}

/**
 * 
 */
interface IFormInputs {
    description: string | null
}

/**
 * 
 */
const initialValues: IFormInputs = {
    description: "",
};

const pictureMaxSize = convertToByteSize(config.fileUploadPictureMaxSize);
const videoMaxSize = convertToByteSize(config.fileUploadVideoMaxSize);

const PostCreation = (props: CreatePostProps) => {
    const hasCameraDevice = useCameraDevice();
    const { t } = useTranslation('post');
    const dispatch = useAppDispatch();
    const [medias, setMedias] = useState<Array<Media>>([]);
    const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [progressText, setProgressText] = useState<string>("");
    const [gallerySelectionError, setGallerySelectionError] = useState<string | null>(null);
    
    const [openPhotoCameraDialog, setPhotoOpenCameraDialog] = useState<boolean>(false);
    const [openVideoCameraDialog, setVideoOpenCameraDialog] = useState<boolean>(false);

    const openPhotoCamera = () => {
        setPhotoOpenCameraDialog(true);
    };

    const openVideoCamera = () => {
        setVideoOpenCameraDialog(true);
    };

    const onValidatePhotoCameraCapture = async (file: File) => {
        setPhotoOpenCameraDialog(false);
        const mediaToAdd = {
            key: mediaKeyGenerator(),
            file: file,
            type: FileType.PICTURE,
            thumbnailUrl: await generatePictureThumbnail(file, 1024),
        };

        setMedias([...medias, mediaToAdd]);
        selectMedia(mediaToAdd);   
    }

    /**
     * 
     */
    const onCancelPhotoCameraCapture = () => {
        setPhotoOpenCameraDialog(false);
    }


    /**
     * 
     * @param file 
     */
    const onValidateVideoCameraCapture = async (file: Blob, metadata: VideoMetadata) => {
        setVideoOpenCameraDialog(false);
        const mediaToAdd = {
            key: mediaKeyGenerator(),
            file: file,
            thumbnailUrl: await generateVideoThumbnail(file, 1024),
            type: FileType.VIDEO,
            metadata: await consolidateVideoMetadata(file, metadata!)
        };

        setMedias([...medias, mediaToAdd]);
        selectMedia(mediaToAdd);
    }

    const onCancelVideoCameraCapture = () => {
        setVideoOpenCameraDialog(false);
    }

    /**
     * 
     */
    const handleMediaGallerySelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setGallerySelectionError(null);
        
        if (event.target.files) {
            for(const file of Array.from(event.target.files)) {
                let mediaProps;

                if(file.type.includes("image/")) {
                    if(file.size > pictureMaxSize) {
                        setGallerySelectionError(t('picture_size_too_big', {"maxSize": convertToReadableSize(pictureMaxSize)}));
                        return;
                    }
                    try {
                        mediaProps = {
                            type : FileType.PICTURE,
                            thumbnailUrl : await generatePictureThumbnail(file, 1024),
                        }
                    } catch(err) {
                        setGallerySelectionError(t('picture_invalid_format', {"name": file.name}));
                        return;
                    }
                } else if(file.type.includes("video/")) {
                    if(file.size > videoMaxSize) {
                        setGallerySelectionError(t('video_size_too_big', {"maxSize": convertToReadableSize(videoMaxSize)}));
                        return;
                    }
                    try {
                        const videoMetadata = await getVideoMetadata(file);
                        mediaProps = {
                            type : FileType.VIDEO,
                            thumbnailUrl : await generateVideoThumbnail(file, 1024),
                            metadata: {
                                duration : videoMetadata.duration,
                                width: videoMetadata.width,
                                height: videoMetadata.height
                            }
                        }
                    } catch(err) {
                        setGallerySelectionError(t('video_invalid_format', {"name": file.name}));
                        return;
                    }
                } else {
                    return;
                }
    
                const mediaToAdd: Media = {
                    key: mediaKeyGenerator(),
                    file: file,
                    ...mediaProps
                };
    
                setMedias([...medias, mediaToAdd]);
                selectMedia(mediaToAdd);
            }
        }
    };

    const onAddMedia = () => {
        setGallerySelectionError(null);
        setSelectedMedia(null);
    };

    const onSelectMedia = (index: number) => {
        selectMedia(medias[index]);
    };

    const selectMedia = (media: Media) => {
        setGallerySelectionError(null);
        setSelectedMedia({
            mediaSource: URL.createObjectURL(media.file),
            ...media
        });
    }

    const onDeleteSelectedMedia = () => {
        if (selectedMedia) {
            const mediaIndex = medias.findIndex((r) => r.key === selectedMedia.key);

            if (mediaIndex > -1) {
                const updatedMedias = [...medias];
                updatedMedias.splice(mediaIndex, 1);
                setMedias(updatedMedias);
                setSelectedMedia(null);
            }
        }
    };

    /**
     * 
     */
    const validationSchema = Yup.object({
        description: Yup.string().nullable().defined()
    });

    /**
     * 
     */
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });

    /**
     * 
     * @param data 
     */
    const onSubmit = async (data: IFormInputs) => {
        setLoading(true);

        const numberLoadingSteps = medias.length + 1 + (props.additionalSubmitSteps ? props.additionalSubmitSteps.length : 0); // number of medias + initial creation step + additional step if any
        const loadingStepPercentage = Math.round(100 / numberLoadingSteps);
        const loadingStepPercentages = Array<number>();
        for (let i = 0; i < (numberLoadingSteps - 1); i++) {
            loadingStepPercentages.push((i + 1) * loadingStepPercentage);
        }
        loadingStepPercentages.push(100);

        setProgressText(t('progress_post_creation'));
        const newPost = await postService.createPost(data.description);
        setProgress(loadingStepPercentages.shift()!!);

        for (const [i, media] of medias.entries()) {

            if(media.type === FileType.PICTURE) {
                setProgressText(`${t('progress_upload_picture')} ${i + 1}/${medias.length}`);
                await postService.addPictureToPost(newPost.id, media.file);
            } else if(media.type === FileType.VIDEO) {
                setProgressText(`${t('progress_upload_video')} ${i + 1}/${medias.length}`);

                await postService.addVideoToPost(
                    newPost.id, 
                    media.file,
                    dataURLtoFile(media.thumbnailUrl, `video-thumbnail-${new Date().toISOString()}`),
                    media.metadata!
                );
            }

            setProgress(loadingStepPercentages.shift()!!);
        }

        if (props.additionalSubmitSteps && props.additionalSubmitSteps.length > 0) {
            await props.additionalSubmitSteps.forEach(async step => {
                setProgressText(`${t(step.label)}`);
                await step.process(newPost);
                setProgress(loadingStepPercentages.shift()!!);
            });
        }

        setProgressText(t('progress_complete'));

        // dispatch & navigate to post
        await dispatch(postSlice.addNewCreatedPost(newPost.id));

        // post submit
        props.navigate(newPost);
    }


    if (loading) {
        return (
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, flexDirection: 'column' }}>
                <LinearProgress variant="determinate" value={progress} sx={{ height: "10px", width: '100%', borderRadius: '10px' }} />
                <Typography variant="caption">{progressText}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ p: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>

                <Box sx={{
                    border: 'dashed',
                    borderWidth: 4,
                    borderColor: 'primary.main',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    position: 'relative'
                }}>

                    {selectedMedia &&
                        <Box sx={{ display: "flex", justifyContent: "center", height: '100%', p: 1, position: 'relative' }}>
                            {selectedMedia.type === FileType.PICTURE && <img src={selectedMedia.mediaSource} alt={"snap"} style={{ maxHeight: '100%', width: 'auto' }} />}
                            {selectedMedia.type === FileType.VIDEO && <PostVideo media={selectedMedia} />}
                        </Box>
                    }

                    {selectedMedia &&
                        <IconButton
                            color="error"
                            component="span"
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0
                            }}
                            onClick={onDeleteSelectedMedia}>
                            <DeleteIcon fontSize="large" />
                        </IconButton>
                    }

                    {!selectedMedia && <>
                        {hasCameraDevice &&  <>
                            <IconButton
                                color="primary"
                                aria-label="take picture"
                                component="span"
                                onClick={openPhotoCamera}>
                                <AddAPhotoIcon fontSize="large" color="primary" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="take video"
                                component="span"
                                onClick={openVideoCamera}>
                                <VideocamIcon fontSize="large" color="primary" />
                            </IconButton>
                        </>}
                        <SelectGalleryPictureButton handleCapture={handleMediaGallerySelection} multiple={true} sx={{color:'primary.main'}}/>
                    </>}

                </Box>

                {gallerySelectionError && <Typography sx={{color: 'error.main', textAlign:'center'}}>{gallerySelectionError}</Typography>}

                <Box sx={{ width: "100%", overflowX: 'auto', my: 2, pb: 1 }}>
                    {medias.length > 0 && 
                        <Box sx={{ display: 'inline-flex' }}>
                            {medias.map((media, index) => {
                                return (
                                    <PostThumbnail media={media} index={index} onSelectMedia={onSelectMedia} key={media.key} />
                                );
                            })}
                            <AddPostThumbnail onAddMedia={onAddMedia} />
                        </Box>
                    }
                </Box>

                <FormControl sx={{ width: '100%' }}>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => <TextField {...field}
                            id="form-description"
                            multiline
                            label={t('input_description_label')}
                            rows={4} />
                        } />
                </FormControl>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2 }}>
                    <Button variant="outlined" color="primary" type="submit" disabled={medias.length === 0}>
                        {t('submit_creation')}
                    </Button>
                </Box>
            </Box>

            <Dialog
                fullScreen
                open={openPhotoCameraDialog}>
                <CameraPhotoCapture 
                    onCancel={onCancelPhotoCameraCapture}
                    onValidate={onValidatePhotoCameraCapture}/>
            </Dialog>
            <Dialog
                fullScreen
                open={openVideoCameraDialog}>
                <CameraVideoCapture 
                    onCancel={onCancelVideoCameraCapture}
                    onValidate={onValidateVideoCameraCapture}/>
            </Dialog>
        </>
    );
}

/**
 * 
 * @param props 
 * @returns 
 */
const PostThumbnail = (props: { index: number, media: Media, onSelectMedia: Function }) => {

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: '100px', height: '100px', overflow: 'hidden', mr: 1, position: 'relative' }} key={props.media.key} onClick={() => props.onSelectMedia(props.index)}>
            {props.media.type === FileType.VIDEO && <VideocamIcon fontSize="small" sx={{color:'white', position:'absolute', top: 0, left: 0}}/>}
            {props.media.type === FileType.VIDEO && props.media.metadata?.duration && <Box sx={{position: 'absolute', bottom: 0, right: 0, color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.8)', mr: 0.5, mb: 0.5, borderRadius: 2}}>{formatduration(props.media.metadata?.duration)}</Box>}
            <img src={props.media.thumbnailUrl} alt={"snap"} />
        </Box>
    );
}

/**
 * 
 * @param props 
 * @returns 
 */
const PostVideo = (props: {  media: Media }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [paused, setPaused] = useState<boolean>(true);

    const onClick = () => {
        if(videoRef.current!.paused) {
            videoRef.current!.play();
            setPaused(false);
        } else {
            videoRef.current!.pause();
            setPaused(true);
        }
    }

    const onEnded = () => {
        setPaused(true);
    }
    

    return (
        <Box>
            {paused && <IconButton onClick={onClick} sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border:'1px solid white'
            }}>
                <PlayArrowIcon sx={{color:'white'}}/>
            </IconButton>}
            <video src={props.media.mediaSource} style={{ maxHeight: '100%', width: 'auto' }} onClick={onClick} ref={videoRef} onEnded={onEnded} />
        </Box>
    );
}

/**
 * 
 * @param props 
 * @returns 
 */
const AddPostThumbnail = (props: { onAddMedia: () => void }) => {
    return (
        <Box sx={{
            display: "flex", justifyContent: "center", width: '100px', height: '100px', overflow: 'hidden', mr: 1, border: 'dashed',
            borderWidth: 2,
            borderColor: '#b1b1b1', borderRadius: '10px'
        }}>
            <IconButton color="primary"
                component="span"
                onClick={props.onAddMedia}>
                <AddCircleOutlineIcon fontSize="large" color="primary" />
            </IconButton>
        </Box>
    );
}

export default PostCreation;