import { Box, Button, Typography } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import AdbIcon from '@mui/icons-material/Adb';
import { useTranslation } from 'react-i18next';
import config from '@app/config';
import SprinklIcon from '@components/icons/SprinklIcon';

export const appBarHeight: number = 70;

const PublicLayout = () => {
    const { t } = useTranslation('public_layout');

    return (
        <>
            <Box sx={{display:'flex', justifyContent: 'space-between', backgroundColor: 'primary.main', height: appBarHeight + 'px', alignItems: 'center', p: 1}}>
                    <Box sx={{ flexGrow: 1, display: 'inline-flex', alignItems: 'center' }}>
                        <SprinklIcon style={{marginRight: '15px'}}/>
                        <Typography
                            variant="h1"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', sm: 'flex' },
                                color: 'inherit',
                                textDecoration: 'none',
                                letterSpacing: '0.5em'
                            }}>
                            SPRINKL
                        </Typography>
                    </Box>
                    <Box>
                        <Link to={"/signin"} style={{ textDecoration: 'none' }}>
                            <Button sx={{ color: 'white' }}>{t('signin')}</Button>
                        </Link>
                        {config.featureToggle.user.signup && 
                            <Link to={"/signup"} style={{ textDecoration: 'none' }}>
                                <Button sx={{ color: 'white' }}>{t('signup')}</Button>
                            </Link>
                        }
                    </Box>
                </Box>
            <Box sx={{height: `calc(100% - ${appBarHeight}px)`}}>
                <Outlet />
            </Box>
        </>
    );
};
export default PublicLayout;