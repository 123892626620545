import { SyntheticEvent } from 'react';
import { Box, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useAppDispatch } from '@app/hooks';

import { commonSlice } from '@features/common/slices'

import { appBarHeight } from '@layouts/AuthenticatedLayout';

interface HeaderProps {
    children ?: React.ReactElement | React.ReactElement[]
    onBack ?: () => void
}

export const Header = (props: HeaderProps) => {
    const dispatch = useAppDispatch();

    const onBackClick = (event: SyntheticEvent) => {
        event.preventDefault();

        if(props.onBack) {
            props.onBack();
        } else {
            dispatch(commonSlice.openDrawer());
        }
    };

    return (
        <Box sx={{ display:'flex', 
            alignItems: 'center', 
            width:'100%', 
            height: appBarHeight + 'px', 
            backgroundColor: 'primary.main', 
            p: 1}}>
            <Button onClick={onBackClick} sx={{display: { xs: 'block', md: props.onBack ? 'block' : 'none' }, mr: 1}}>
                <ArrowBackIosNewIcon sx={{ color:'white' }}/>
            </Button>
            {props.children}
        </Box>
    );
}