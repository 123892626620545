import { Size } from '@features/common/slices/commonSlice';
import { margin } from '@features/post/components/GenericThumbnailPost';

export const defaultThumbnailPostWidth = 150;

export const computeThumbnailPostSize = (containerSize: Size): {postPerRow: number, postSize: number} => {
    // how many posts 
    const postPerRow = containerSize.width / (defaultThumbnailPostWidth + margin * 2);
    // number without decimals -> it means we can use the defaultPostWidth
    if(Math.floor(postPerRow) === postPerRow) {
        return {
            postSize: defaultThumbnailPostWidth,
            postPerRow: postPerRow
        };
    } else {
        // if we use the defaultPostWidth, we will have empty spaces
        // so we reduce the width to add 1 more post on the row and fill it completly
        return {
            postSize: Math.floor(containerSize.width / (Math.floor(postPerRow) + 1)) - (margin * 2),
            postPerRow: Math.floor(postPerRow) + 1
        };
    }
}