import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import StopIcon from '@mui/icons-material/Stop';
import DoneIcon from '@mui/icons-material/Done';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CameraStyle from './CameraCaptureStyle';
import { formatduration } from "@helpers/date.helper";
import { roundToDecimal } from '@helpers/number.helper';
import { VideoMetadata } from "../models";

const finalWidth = 1080;

const videoDuration = 10000; // 10sec

enum State {
    PREVIEW,
    RECORDING,
    FINAL_RENDER
}

export interface StreamSettings {
    width: number | undefined,
    height: number | undefined
}

/**
 * 
 */
interface CameraVideoCapture {
    onCancel: () => void,
    onValidate: (video: Blob, metadata: VideoMetadata) => void
}

/**
 * To override the standard video element type because of a missing method
 */
interface HTMLMediaElementWithCaptureStream extends HTMLVideoElement {
    captureStream(): MediaStream;
}

/**
 * Tool function to trigger an action after a delay
 * @param delayInMS 
 * @returns 
 */
const wait = async (delayInMS: number) => {
    return new Promise((resolve) => setTimeout(resolve, delayInMS));
}

const CameraVideoCapture = (props: CameraVideoCapture) => {
    const videoRef = useRef<HTMLMediaElementWithCaptureStream>(null);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [hasFrontCamera, setHasFrontCamera] = useState<boolean>(false);
    const [cameraFacingMode, setCameraFacingMode] = useState<string>('environment');
    const [state, setState] = useState<State>(State.PREVIEW);
    const [finalRenderPaused, setFinalRenderPaused] = useState<boolean>(true);
    const [recordingDuration, setRecordingDuration] = useState<number>(0);

    const recordingTimerInterval = useRef<number | null>(null);
    const recorder = useRef<MediaRecorder | null>(null);
    const stream = useRef<MediaStream | null>(null);
    const streamSettings = useRef<StreamSettings | null>(null);
    const finalData = useRef<Blob | null>(null);
    const finalDataUrl = useRef<string | null>(null);

    useEffect(() => {
        (async() => {
            // now we have permission to see the camera devices, check if there are more than 1. If yes, let's consider there is a "user" one
            // and display a button to switch camera
            const devices = await navigator.mediaDevices.enumerateDevices();
            setHasFrontCamera(devices.filter(d => d.kind === 'videoinput').length > 1);
        })();

        return () => stopCamera();
    }, []);

    /**
     * Start the camera device and play it in the video to see the preview
     */
    const startCamera = async () => {
        finalData.current = null;
        finalDataUrl.current = null;
        setRecordingDuration(0);
        setState(State.PREVIEW);

        stream.current = await navigator.mediaDevices.getUserMedia({ 
            video: {
                facingMode: cameraFacingMode,
                width: {
                    min: 320,
                    max: 1280
                },
                height: {
                    min: 240,
                    max: 720
                }
            }, 
            audio: false 
        });
        videoRef.current!.srcObject = stream.current;

        const settings = stream.current.getTracks()[0].getSettings();
        streamSettings.current = {
            width: settings.width,
            height: settings.height
        };

        await videoRef.current!.play();
    };

    /**
     * Close all media streams linked to the preview 
     */
    const stopCamera = () => {
        if(stream.current) {
            stream.current.getTracks().forEach(track => track.stop());
        }
        if(videoRef.current) {
            videoRef.current!.srcObject = null;
        }
    }

    /**
     * Switch the facing mode (only works if several camera available for mobile)
     */
    const switchCamera = async() => {
        if(cameraFacingMode === 'environment') setCameraFacingMode('user');
        else setCameraFacingMode('environment');
    }

    /**
     * Restart the camera preview if the facing mode has changed
     * Also used to start it the first time 
     */
    useEffect(() => {
        stopCamera();
        (async() => {
            await startCamera();
        })();
    }, [cameraFacingMode]);

    /**
     * Function that actually record the stream by storing it in a buffer
     * it also triggers a timer to automatically stop the recording after a given time
     */
    const record = async (stream: MediaStream) => {
        const options = {
            mimeType: 'video/webm;codecs=vp8.0,opus',
        };
        recorder.current = new MediaRecorder(stream, options);
        const bufferData: Blob[] = [];

        recorder.current.ondataavailable = (event) => bufferData.push(event.data);
        recorder.current.start();

        startRecordingTimer();

        wait(videoDuration).then(() => {
            if (recorder.current!.state === "recording") {
                onStopRecording();
            }
        });

        return new Promise((resolve, reject) => {
            recorder.current!.onstop = resolve;
            recorder.current!.onerror = (event) => reject(event);
        }).then(() => bufferData);
    };

    /**
     * Start the recording timer
     */
    const startRecordingTimer = () => {
        recordingTimerInterval.current = window.setInterval(() => {
            setRecordingDuration((prev) => prev + 1);
        }, 1000);
    }

    /**
     * Start the recording of the stream
     */
    const onStartRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: {facingMode: cameraFacingMode}, audio: true });

        videoRef.current!.srcObject = stream;

        await videoRef.current!.play();
        setState(State.RECORDING);

        const dataChunks = await record(videoRef.current!.captureStream());

        const recordedBlob = new Blob(dataChunks, { type: "video/webm" });

        finalData.current =  recordedBlob;
        finalDataUrl.current = URL.createObjectURL(recordedBlob);

        stopCamera();

        videoRef.current!.src = finalDataUrl.current;

        setState(State.FINAL_RENDER);
        setFinalRenderPaused(true);
    };

    /**
     * When the user ends the recording of after the X sec it is automatically called
     */
    const onStopRecording = () => {
        stopCamera();

        if(recordingTimerInterval.current) {
            window.clearInterval(recordingTimerInterval.current);
        }

        if(recorder.current) {
            recorder.current.stop();
        }
    };

    /**
     * When the user deletes the captured video
     * start the preview of the camera again
     */
    const onDeleteVideo = async () => {
        await startCamera();
    }

    /**
     * The user validates the video, capture the first picture of it for the thumbnail
     * and pass it to the parent
     */
    const onValidateVideo = async () => {
        if(finalData.current && finalDataUrl.current) {
            const duration = Number.isFinite(videoRef.current!.duration) ? Math.round(videoRef.current!.duration) : null;
            props.onValidate(
                finalData.current, 
                {
                    duration: duration,
                    width: streamSettings.current?.width,
                    height: streamSettings.current?.height,
                }
            );
        }

        stopCamera();
    }

    /**
     * When the user leaves the component 
     */
    const onCancel = () => {
        stopCamera();
        props.onCancel();
    }

    /**
     * When the user plays the captured video to review it
     */
    const onPlayFinalRender = () => {
        videoRef.current!.play();
        setFinalRenderPaused(false);
    }

    /**
     * When the user plays the captured video to review it
     */
    const onVideoClick = () => {
        if(state === State.FINAL_RENDER) {
            if(videoRef.current!.paused) {
                videoRef.current!.play();
                setFinalRenderPaused(false);
            } else {
                videoRef.current!.pause();
                setFinalRenderPaused(true);
            }
        }
    }

    /**
     * 
     */
    const onVideoEnd = () => {
        if(state === State.FINAL_RENDER) {
            videoRef.current!.currentTime = 0;
            setFinalRenderPaused(true);
        }
    }

    return (
        <Box sx={{width: '100%', height: '100%', background: 'black'}}>
            <video ref={videoRef} 
                style={CameraStyle.video} 
                muted={state !=  State.FINAL_RENDER}
                onClick={onVideoClick} 
                onEnded={onVideoEnd}
                onDurationChange={() => {}}
                onLoadedMetadata={() => {}}
                >Video stream not available.</video>

            <Box sx={CameraStyle.cancelContainer}>
            <IconButton onClick={onCancel} sx={CameraStyle.iconButton}>
                    <ClearIcon sx={CameraStyle.icon} />
                </IconButton>
            </Box>

            <Box sx={CameraStyle.controlContainer}>
                    {state === State.PREVIEW && hasFrontCamera &&  <IconButton onClick={switchCamera} sx={{...CameraStyle.iconButton, mr: 2}}>
                            <CameraswitchIcon sx={CameraStyle.icon} />
                        </IconButton>
                    }
                    {state === State.PREVIEW && <IconButton onClick={onStartRecording} sx={CameraStyle.iconButton}>
                        <FiberManualRecordIcon sx={CameraStyle.icon} />
                    </IconButton>}
                    {state === State.RECORDING && <IconButton onClick={onStopRecording} sx={CameraStyle.iconButton}>
                        <StopIcon sx={CameraStyle.icon} />
                    </IconButton>}
                    {state === State.FINAL_RENDER && <>
                        <IconButton onClick={onDeleteVideo} sx={{...CameraStyle.iconButton, mr: 2}}>
                            <DeleteIcon sx={CameraStyle.icon} />
                        </IconButton>
                        <IconButton onClick={onValidateVideo} sx={CameraStyle.iconButton}>
                            <DoneIcon sx={CameraStyle.icon} />
                        </IconButton>
                    </>}

                    {state !== State.PREVIEW && 
                        <Typography sx={{ml: 2, background: 'rgba(0, 0, 0, 0.3)', borderRadius: 2, px: 1, color: 'white', display: 'inline-flex'}}>{formatduration(recordingDuration)}</Typography>
                    }
            </Box>

            {state === State.FINAL_RENDER && finalRenderPaused && <IconButton onClick={onPlayFinalRender} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border:'1px solid white'
            }}>
                <PlayArrowIcon sx={{color:'white'}}/>
            </IconButton>}

            <canvas ref={canvasRef} style={{display:'none'}}></canvas>
        </Box>
    );
}

export default CameraVideoCapture;