import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch } from './store';
import type { RootState } from './rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export declare type AppThunkDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export declare type AppThunkAction<ReturnType = void> = {
  (dispatch: AppThunkDispatch, getState: () => RootState, extraArgument: undefined): ReturnType;
}

export type PromiseResolve<T = void> = (value: T | PromiseLike<T>) => void; 
export type PromiseReject = (error?: any) => void; 