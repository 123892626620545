
export enum BusinessErrorType {
    VALIDATION,
    NOT_FOUND,
    OTHER
}

/**
 * 
 */
export class BusinessError extends Error {
    type ?: BusinessErrorType;
    domain: string;
    code: string;
    message: string;
    cause ?: Error;
    context: Map<string, string>;

    constructor(type: BusinessErrorType, domain: string, code: string, message: string);
    constructor(type: BusinessErrorType, domain: string, code: string, message: string, context: Map<string, string>);
    public constructor(...args: any[]) {
        super();

        if (args.length === 4) {
            this.context = new Map();
        } else if(args.length === 5) {
            this.context = new Map();
            if(args[4]) {
                Object.entries(args[4]).forEach(e => {
                    this.context.set(e[0], e[1] as string);
                });
            }
        }else {
            this.message = `Not defined Business Error - should not occur`;
            this.context = new Map();
        }
        
        this.type = args[0];
        this.domain = args[1];
        this.code = args[2];
        this.message = args[3];
    }


    isValidationError(): boolean {
        return this.type === BusinessErrorType.VALIDATION;
    }

    isNotFoundError(): boolean {
        return this.type === BusinessErrorType.NOT_FOUND;
    }
}

/**
 * 
 */
export class UnmanagedError extends Error {
    cause ?: Error;

    constructor(cause: Error);
    constructor(message: String);
    constructor(cause: Error, message: String);
    public constructor(...args: any[]) {
        if(args.length === 1) {
            if(args[0] instanceof Error) {
                super("Unmanaged exception with cause");
                this.cause = args[0];
            } else {
                super(args[0]);
            }
        } else if (args.length === 2){
            super(args[1]);
            this.cause = args[0];
        } else {
            super("Not defined unmanaged error - should not occur");
        }
    }
}