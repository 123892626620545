import { roundToDecimal } from '@helpers/number.helper';

const units = new Map<string, number>([
    ['b', 0],
    ['kb', 1],
    ['mb', 2],
    ['gb', 3],
    ['tb', 3]
]);
const unitKeys = Array.from( units.keys() );


export const convertToByteSize = (value: string) => {
    const parts = [...value.toLowerCase().trim().matchAll(/([\d.]+)([a-z]*)/g)];
    
    if (!parts || parts.length !== 1 || parts[0].length !== 3 ) {
        throw new Error(`Unexcepted size to parse ${value}`);
    }

    const quantity = Number.parseInt(parts[0][1]);
    const unit = parts[0][2];
    const pow = units.get(unit);

    if(!pow) {
        throw new Error(`Unexcepted unit: ${unit}`);
    }

    return quantity * Math.pow(1024, pow);
};

/**
 * 
 * @param value 
 */
export const convertToReadableSize = (value: number) => {
    const i = value == 0 ? 0 : Math.floor(Math.log(value) / Math.log(1024));
    return roundToDecimal(value / Math.pow(1024, i), 2) + ' ' + unitKeys[i];
}