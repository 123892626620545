import { Post } from "@features/post/models"

export enum CircleScope {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
}

export interface Circle {
    id: string,
    name: string,
    lastReadPostId: string | null,
    settings: CircleSettings,
    creationDate: string,
    nbPosts: number,
    authorizations: Array<CircleAuthorization>
}

export interface CircleSettings {
    hideUsers: boolean,
    allowComments: boolean,
    allowReactions: boolean,
    scope: CircleScope
}

export enum CircleUserRole {
    OWNER = "OWNER",
    ADMIN = "ADMIN",
    EDITOR = "EDITOR",
    READER = "READER"
}

export interface CircleUser {
    id: string
    username: string
    picture: boolean
    role: CircleUserRole
}

export interface CircleOverview {
    id: string,
    name: string,
    unread: number
}

export interface CirclePost extends Post {
    focused ?: boolean,
    date: string
    authorizations: Array<CirclePostAuthorization>
}

export enum CircleAuthorization {
    SHOW_TITLE_USERS = "SHOW_TITLE_USERS",
    SHOW_ALL_USERS = "SHOW_ALL_USERS",
    ALLOW_REACTIONS = "ALLOW_REACTIONS",
    ALLOW_COMMENTS = "ALLOW_COMMENTS",
    EDIT_CIRCLE = "EDIT_CIRCLE",
    ADD_POST = "ADD_POST",
    REMOVE_POST = "REMOVE_POST",
    MANAGE_USERS = "MANAGE_USERS",
    DELETE_CIRCLE = "DELETE_CIRCLE",
    LEAVE_CIRCLE = "LEAVE_CIRCLE"
}

export enum CirclePostAuthorization {
    DELETE_POST = "DELETE_POST"
}