import Paths from "@app/routes";

export interface CircleTab {
    type: CircleTabType
    route: string
    backTab ?: CircleTab
}

export enum CircleTabType {
    POSTS,
    SETTINGS,
    ADD_POST,
    CREATE_POST,
    SELECT_INSTRAGRAM_POST,
    MANAGE_CONTACTS,
    DETAILS,
    VIEW_ALL_POSTS
}
  
export class CircleTabs {
static POSTS: CircleTab = {
    type: CircleTabType.POSTS,
    route: Paths.Authenticated.circle
};
static SETTINGS: CircleTab = {
    type: CircleTabType.SETTINGS,
    route: Paths.Authenticated.circleSettings,
    backTab: CircleTabs.POSTS
};
static ADD_POST: CircleTab = {
    type: CircleTabType.ADD_POST,
    route: Paths.Authenticated.circleAddPost,
    backTab: CircleTabs.POSTS
};
static CREATE_POST: CircleTab = {
    type: CircleTabType.CREATE_POST,
    route: Paths.Authenticated.circleCreatePost,
    backTab: CircleTabs.ADD_POST
};
static SELECT_INSTRAGRAM_POST: CircleTab = {
    type: CircleTabType.SELECT_INSTRAGRAM_POST,
    route: Paths.Authenticated.circleSelectInstagramPost,
    backTab: CircleTabs.ADD_POST
};
static MANAGE_CONTACTS: CircleTab = {
    type: CircleTabType.MANAGE_CONTACTS,
    route: Paths.Authenticated.circleContacts,
    backTab: CircleTabs.POSTS
};
static DETAILS: CircleTab = {
    type: CircleTabType.DETAILS,
    route: Paths.Authenticated.circleDetails,
    backTab: CircleTabs.POSTS
};
static VIEW_ALL_POSTS: CircleTab = {
    type: CircleTabType.VIEW_ALL_POSTS,
    route: Paths.Authenticated.circleViewAllPosts,
    backTab: CircleTabs.DETAILS
};

static ALL: Array<CircleTab> = [CircleTabs.POSTS, 
    CircleTabs.VIEW_ALL_POSTS, 
    CircleTabs.DETAILS, 
    CircleTabs.SETTINGS, 
    CircleTabs.ADD_POST, 
    CircleTabs.SELECT_INSTRAGRAM_POST,
    CircleTabs.CREATE_POST, 
    CircleTabs.MANAGE_CONTACTS];

static findByRoute = (route: string) : CircleTab | undefined => {
    return CircleTabs.ALL.find(tab => tab.route === route);
}

static findByType = (type: CircleTabType): CircleTab | undefined => {
    return CircleTabs.ALL.find(tab => tab.type === type);
}
}