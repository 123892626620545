import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, Outlet} from 'react-router-dom';
import React, { SyntheticEvent, useEffect } from 'react';
import { Badge, Box, Drawer, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material"
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {  useAppDispatch, useAppSelector} from '@app/hooks';
import Paths from '@app/routes';

import { UserAvatar } from "@components/icons/UserAvatar";

import { ListCircles } from "@features/circle/components/ListCircles";
import { circleSlice, circleSelector } from "@features/circle/slices";

import { commonSlice, commonSelector } from '@features/common/slices';

import { postSlice } from '@features/post/slices';

import { userSlice, userSelector, notificationSlice, notificationSelector } from '@features/user/slices';
import { hasPrivilege } from '@helpers/security.helper';
import { UserPrivilege } from '@features/user/models';

const drawerWidth = 350;

export const appBarHeight: number = 70;

const AuthenticatedLayout = () => {
    const theme= useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { openDrawer } = useAppSelector(commonSelector);
    const { user } = useAppSelector(userSelector);
    const { circles } = useAppSelector(circleSelector);
    const { unread: unreadNotifications } = useAppSelector(notificationSelector);

    const { t } = useTranslation('authenticated_layout');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

    const onOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };

    const onCloseUserMenu = () => {
        setAnchorElMenu(null);
    };

    // called only once when displaying the page for the first time after sigin or after refreshing it
    useEffect(() => {
        dispatch(notificationSlice.checkUnreadNotifications());
    }, []);

    // load circles on top level else will be done twice -> for each instance of the ListCircles component
    useEffect(() => {
        if(circles == null) {
            dispatch(circleSlice.loadCircles());
        }
    }, []);

    /**
     * 
     * @param ev 
     */
    const submitLogout = async (ev: SyntheticEvent) => {
        ev.preventDefault();
        onCloseUserMenu();
        await dispatch(userSlice.logout());
        navigate(Paths.Public.root);
    };

    const navivateToPost = () => {
        dispatch(postSlice.resetPostsState());
        dispatch(commonSlice.closeDrawer());
        navigate(Paths.Authenticated.posts);
    };

    const navigateToCircleCreation = () => {
        dispatch(commonSlice.closeDrawer());
        navigate(Paths.Authenticated.circleCreation);
    }

    const navigateToManageUsers = () => {
        dispatch(commonSlice.closeDrawer());
        navigate(generatePath(Paths.Authenticated.manageContacts, {tab: ''}));
    }

    const navigateToNotification = () => {
        dispatch(commonSlice.closeDrawer());
        navigate(Paths.Authenticated.notifications);
    };

    const navigateToAccount = () => {
        onCloseUserMenu();
        dispatch(commonSlice.closeDrawer());
        navigate(Paths.Authenticated.account);
    }

    const navigateToExternalAccounts = () => {
        onCloseUserMenu();
        dispatch(commonSlice.closeDrawer());
        navigate(Paths.Authenticated.externalAccounts);
    }


    const appBar = <Box sx={{display:'flex', justifyContent: 'space-between', backgroundColor: 'primary.main', height: appBarHeight + 'px', alignItems: 'center', p: 1}}>
            <IconButton onClick={onOpenUserMenu}>
                <UserAvatar user={user!} sx={{mr: 1}} />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElMenu)}
                onClose={onCloseUserMenu}>
                <MenuItem onClick={navigateToAccount}>
                    {t('account')}
                </MenuItem>
                <MenuItem onClick={navigateToExternalAccounts}>
                    {t('external_accounts')}
                </MenuItem>
                <MenuItem onClick={submitLogout}>
                    {t('logout')}
                </MenuItem>
            </Menu>
            
            <div>
                <Badge badgeContent={unreadNotifications} max={99} color="secondary" sx={{
                    '& .MuiBadge-badge': {right: '5px', top: '5px'}}}>
                    <IconButton onClick={navigateToNotification}>
                        <NotificationsIcon />
                    </IconButton>                    
                </Badge>
                <IconButton onClick={navivateToPost}>
                    <AddAPhotoIcon/>
                </IconButton>
                {hasPrivilege(user, UserPrivilege.CIRCLE_CREATION) && 
                    <IconButton onClick={navigateToCircleCreation}>
                        <AddCircleOutlineIcon/>
                    </IconButton>
                }
                <IconButton onClick={navigateToManageUsers}>
                    <GroupIcon />
                </IconButton>
            </div>
        </Box>;

    return (
        <>
            {smallScreen && 
                <Drawer
                    variant="temporary"
                    open={openDrawer}
                    transitionDuration={300}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: '100%', boxSizing: 'border-box'},
                    }}>
                    {appBar}
                    <ListCircles />
                </Drawer>
            }

            <Box sx={{ display: 'flex', 
                height:'100%', 
                }}>
                
                {!smallScreen && 
                    <Box sx={{flexBasis:`${drawerWidth}px`, flexShrink: 0, height: '100%', overflowY: 'auto'}}>
                        {appBar}
                        <ListCircles />
                    </Box>
                }

                <Box component="main" sx={{ flexGrow: 1, p: 0, height:'100%', overflow:'hidden'}}>
                    <Outlet></Outlet>
                </Box>
            </Box>
        </>
    );
};
export default AuthenticatedLayout;