import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "@app/hooks";
import { Contact, ContactInvitation, ContactInvitationStatus } from "@features/contact/models";
import contactService from '@features/contact/services/contactService';
import contactInvitationService from '@features/contact/services/contactInvitationService';

export interface ContactState {
  contacts: Array<Contact> | null
  loadingPage: number
  hasMoreContacts: boolean
}

const initialState: ContactState = {
    contacts: null,
    loadingPage: 0,
    hasMoreContacts: true
};


const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    initialContactsLoad: (state, action: PayloadAction<{hasNext: boolean , contacts: Array<Contact>}>) => {
      state.contacts = action.payload.contacts;
      state.hasMoreContacts = action.payload.hasNext;
      state.loadingPage = state.loadingPage + 1;
    },
    addContacts: (state, action: PayloadAction<{hasNext: boolean , contacts: Array<Contact>}>) => {
      if(state.contacts == null) {
        state.contacts = [];
      }
      
      state.contacts = state.contacts.concat(action.payload.contacts);
      state.hasMoreContacts = action.payload.hasNext;
      state.loadingPage = state.loadingPage + 1;
    },
    resetState: (state) => {
      return initialState
    },
    deleteContact: (state, action: PayloadAction<Contact>) => {
      if(state.contacts) {
        state.contacts = state.contacts.filter(c => c.userId !== action.payload.userId);
      }
    },
    addContact: (state, action: PayloadAction<Contact>) => {
      if(state.contacts) {
        const contacts = [action.payload, ...state.contacts];
        contacts.sort((a, b) => a.username < b.username ? 1 : -1);
        state.contacts = contacts;
      }
    }
  },
});

/**
 * 
 * @param page 
 * @returns 
 */
 const loadContacts = (): AppThunkAction<Promise<Array<Contact>>> => {
  return async (dispatch, getState) => {
    const { loadingPage } = getState().contact;

    const contacts = await contactService.loadContacts(loadingPage);
    if(loadingPage === 0) {
      await dispatch(contactSlice.actions.initialContactsLoad({hasNext: contacts.hasNext, contacts: contacts.contacts}));
    } else {
      await dispatch(contactSlice.actions.addContacts({hasNext: contacts.hasNext, contacts: contacts.contacts}));
    }

    return contacts.contacts;
  }
};

/**
 * 
 * @param contact 
 */
const deleteContact = (contact: Contact): AppThunkAction => {
  return async (dispatch) => {
    await contactService.deleteContact(contact.userId);
    await dispatch(contactSlice.actions.deleteContact(contact));
  }
};

/**
 * 
 * @param contact 
 */
const addContact = (contact: Contact): AppThunkAction<Promise<ContactInvitation>> => {
  return async (dispatch) => {
    const invitation = await contactInvitationService.sendInternalInvitation(contact.userId);
    if(invitation.status === ContactInvitationStatus.ACCEPTED) {
      await dispatch(contactSlice.actions.addContact(contact));
    }
    return invitation;
  }
};


export const contactSelector = (state: { contact: ContactState }) => state.contact;

export const reducer = contactSlice.reducer;

const slice = {
  loadContacts,
  deleteContact,
  addContact,
  resetState: contactSlice.actions.resetState
};

export default slice;