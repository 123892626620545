import axios from '@app/axios';
import { Circle, CircleOverview, CircleSettings} from '@features/circle/models';


const findCircleById = async (circleId: string): Promise<Circle> => {
    const { data }= await axios.get(`/circles/${circleId}`)
    return data as Circle;
};

const findCircles = async (): Promise<Array<CircleOverview>> => {
    const { data } = await axios.get('/circles');
    return data;
};

/**
 * 
 * @param name 
 * @param icon 
 */
const createCircle = async (name: string, settings: CircleSettings, icon ?: File): Promise<Circle> => {
    const { data } = await axios.post("/circles", {
        name,
        settings
     });
     return data;
};

/**
 * 
 * @param circleId 
 * @param name 
 * @param settings 
 * @param icon 
 * @returns 
 */
const updateCircle = async (circleId: string, name: string, icon ?: File) : Promise<Circle>=> {
    const { data } = await axios.put(`/circles/${circleId}`, {
        name
    });
    return data;
};

/**
 * 
 * @param circleId 
 * @param name 
 * @param settings 
 * @param icon 
 * @returns 
 */
 const updateCircleSettings = async (circleId: string, settings: CircleSettings): Promise<Circle> => {
    const { data } = await axios.put(`/circles/${circleId}/settings`, {
        hideUsers: settings.hideUsers,
        allowComments: settings.allowComments,
        allowReactions: settings.allowReactions,
        scope: settings.scope
    });
    return data;
};

/**
 * 
 * @param circleId 
 */
const deleteCircle = async (circleId: string) => {
    await axios.delete(`/circles/${circleId}`);
};

const service = {
    findCircles,
    findCircleById,
    createCircle,
    updateCircle,
    deleteCircle,
    updateCircleSettings
};

export default service;