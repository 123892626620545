import { Alert, AlertColor, Snackbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '@app/hooks';
import { BusinessError } from '@app/errors';
import Paths from '@app/routes';

import { globalErrorChannel, GlobalErrorNotification, securityErrorChannel, successChannel } from '@channels/globalNotificationChannel'

import { userSlice } from '@features/user/slices'


export const CommonLayout = () => {
    const { t } = useTranslation('public_layout');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [openAlert, setOpenAlert] = useState(false);
    const [alert, setAlert] = useState<{severity: AlertColor, message: string}>();

    /**
     * 
     * @param event 
     */
    function onGlobalErrorEvent(event: GlobalErrorNotification){
        let message;
        if(event.message) {
            message = event.message;
        } else if (event.error) {
            if(event.error instanceof BusinessError) {
                message = event.error.message;
            } else {
                message = t('errors', 'unexpected')
            }
        } else {
            message = t('errors', 'technical');
        }
        
        setAlert({severity: 'error', message: message});
        setOpenAlert(true);
    }

    /**
     * 
     * @param event 
     */
    function onSuccessEvent(event: string) {
        setAlert({severity: 'success', message: event});
        setOpenAlert(true);
    }

    /**
     * 
     */
    function onSecurityErrorEvent() {
        dispatch(userSlice.logout());
        navigate(Paths.Public.root);
    }

    useEffect(() => {
        successChannel.on(onSuccessEvent);
        globalErrorChannel.on(onGlobalErrorEvent);
        securityErrorChannel.on(onSecurityErrorEvent);

        return function cleanUp() {
            successChannel.off(onSuccessEvent);
            globalErrorChannel.off(onGlobalErrorEvent);
            securityErrorChannel.off(onSecurityErrorEvent)
        }
    }, []);

    /**
     * 
     * @param event 
     * @param reason 
     * @returns 
     */
    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
      };

    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={alert?.severity} sx={{ width: '100%' }}>
                    {alert?.message}
                </Alert>
            </Snackbar>

            <Outlet />
        </>
    );
};