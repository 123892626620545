import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import Paths from '@app/routes';

import { Content } from "@components/Content";
import { Header } from "@components/Header";

import PostCreation from "@features/post/components/PostCreation";
import { Post } from "@features/post/models";

const CreatePost = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('post');

    const navigateToViewPosts = (post: Post) => {
        navigate(Paths.Authenticated.posts);
    }

    const onBack = () => {
        navigate(Paths.Authenticated.posts);
    };

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2">{t('creation_title')}</Typography>
            </Header>
            <Content>
                <PostCreation navigate={navigateToViewPosts}/>
            </Content>
        </>
    );
};

export default CreatePost;