import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LensRoundedIcon from '@mui/icons-material/LensRounded';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import MultipleIcon from "@components/icons/MultipleIcon";
import VideocamIcon from '@mui/icons-material/Videocam';
import useLongPress from '@hooks/useLongPress';

interface GenericPost {
    id: string
    thumbnailUri: string | null
    type ?: GenericPostType
    description ?: string | null
}

export enum GenericPostType {
    VIDEO = "VIDEO",
    ALBUM = "ALBUM",
    DEFAULT = "DEFAULT"
}

interface GenericThumbnailPostProps {
    post: GenericPost
    size: number
    selectable ?: boolean
    checked ?: boolean
    onClick ?: (post: GenericPost) => void
    onLongPress ?: (post: GenericPost) => void
}

export const margin = 5; // margin for each side

export const GenericThumbnailPost = (props: GenericThumbnailPostProps) => {
    const post = props.post;
    const size = props.size;

    const pressHandlers = useLongPress(() => {
        if(props.onLongPress) {
            props.onLongPress(post);
        }
    }, {
        onClick: () => {
            if(props.onClick) {
                props.onClick(post);
            }
        }
    });

    return (
        <div style={{width: size + 'px', height: size + 'px', margin: margin + 'px', display: 'flex', position: 'relative', cursor: "pointer"}} {...pressHandlers}>
            {props.selectable && 
                <div style={{
                    position: 'absolute',
                    left: 0,
                    width: '24px',
                    height: '24px',
                    background:'rgb(0,0,0,0.3)',
                    borderRadius:'12px'
                }}>
                    {props.checked && <CheckCircleIcon sx={{color:'white'}}/>}
                    {!props.checked && <LensRoundedIcon sx={{color:'white'}} />}
                </div>
            }
            {post.type === GenericPostType.ALBUM &&
                <div style={{
                    position: 'absolute',
                    right: 0
                }}>
                    <MultipleIcon sx={{color:'white'}}/>
                </div>
            }
            {post.type === GenericPostType.VIDEO &&
                <div style={{
                    position: 'absolute',
                    right: 0
                }}>
                    <VideocamIcon sx={{color:'white'}}/>
                </div>
            }

            {post.thumbnailUri && <img 
                src={post.thumbnailUri}
                alt={`Picture of post ${post.id}`}
                style={{width: '100%', height: '100%'}} /> }

            {!post.thumbnailUri && <InsertPhotoIcon sx={{width: '100%', height: '100%', color: '#BBB'}}/> }

            {post.description && post.description.trim().length > 0 && <Box sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                display: 'flex',
                color: 'white',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.5)',
                p: 1
            }}>
                <Typography variant="subtitle2" noWrap sx={{overflow:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    {post.description}
                </Typography>
            </Box>}

        </div>
    );
}