import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { generatePath, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { successChannel } from "@app/channels/globalNotificationChannel";

import { circleSlice, circleSelector } from "@features/circle/slices";

import { CircleSettingsForm, IFormInputs as ICircleSettingsInputs } from '@features/circle/components/CircleSettingsForm';
import { CircleScope } from '@features/circle/models';

import { deepEqual } from "@helpers/objects.helper";


interface IFormInputs extends ICircleSettingsInputs {
}

export const UpdateCircleSettings = () => {
    const { t } = useTranslation('circle_update');
    const { t: ct } = useTranslation('common');
    const { circle } = useAppSelector(circleSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    /**
     * 
     */
    const validationSchema = Yup.object({
        name: Yup.string().required(ct('input_required')),
        hideUsers: Yup.boolean().defined(),
        allowComments: Yup.boolean().defined(),
        allowReactions: Yup.boolean().defined(),
        scope: Yup.mixed<CircleScope>().oneOf(Object.values(CircleScope)).defined()
    });

    /**
     * 
     */
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            hideUsers: circle!.settings.hideUsers,
            allowComments: circle!.settings.allowComments,
            allowReactions: circle!.settings.allowReactions,
            name: circle!.name,
            scope: circle!.settings.scope
        }
    });

    /**
     * 
     * @param data 
     */
    const onSubmit = async (data: IFormInputs) => {
        if(circle) {
            setLoading(true);

            if(circle.name !== data.name) {
                await dispatch(circleSlice.updateCircle(circle.id, data.name));
            }

            const updatedSettings = {
                hideUsers: data.hideUsers,
                allowComments: data.allowComments,
                allowReactions: data.allowReactions,
                scope: data.scope
            };

            if(!deepEqual(circle.settings, updatedSettings)) {
                await dispatch(circleSlice.updateCircleSettings(circle.id, updatedSettings));
            }

            successChannel.emit(t('notification_update_success'));
            navigate(generatePath(Paths.Authenticated.circle, {"circleId": circle.id}));
        }
    }

    return (
        <Box sx={{ p: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>

            <CircleSettingsForm control={control} errors={errors}/>
        
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2 }}>
                <LoadingButton loading={loading} variant="outlined" color="primary" type="submit" >
                    {ct('validate_button')}
                </LoadingButton>
            </Box>
        </Box>
    )
}