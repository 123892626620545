import { eventbus } from './eventBus'

const notificationChannel = eventbus<{
  globalError: (payload: GlobalErrorNotification) => void,
  securityError: () => void,
  success: (payload: string) => void
}>();

export const globalErrorChannel = {
  emit: (message: string, error ?: any) => notificationChannel.emit('globalError', {
    message: message,
    error: error
  }),
  on: (listener: (event: GlobalErrorNotification) => void) => notificationChannel.on('globalError', listener),
  off: (listener: (event: GlobalErrorNotification) => void) => notificationChannel.off('globalError', listener),
}

export const securityErrorChannel = {
  emit: () => notificationChannel.emit('securityError'),
  on: (listener: () => void) => notificationChannel.on('securityError', listener),
  off: (listener: () => void) => notificationChannel.off('securityError', listener),
};

export const successChannel = {
  emit: (message: string) => notificationChannel.emit('success', message),
  on: (listener: (event: string) => void) => notificationChannel.on('success', listener),
  off: (listener: (event: string) => void) => notificationChannel.off('success', listener),
};

export enum NotificationSeverity {
  ERROR,
  SUCCESS
}

export interface GlobalErrorNotification {
  message ?: string
  error ?: any
}