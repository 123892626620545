import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "@app/hooks";
import circleUserService from '@features/circle/services/circleUserService';
import { CircleUser } from '@features/circle/models';


export interface CircleUserState {
  users: Array<CircleUser> | null
}

const initialState: CircleUserState = {
  users: null
};


const circleUserSlice = createSlice({
  name: "circleUser",
  initialState,
  reducers: {
    loadUsers: (state, action: PayloadAction<Array<CircleUser>>) => {
      state.users = action.payload;
    },
    resetCircleUserState: () => {
      return initialState;
    },
    update: (state, action: PayloadAction<Array<CircleUser>>) => {
      state.users = action.payload;
    },
    addUsers: (state, action: PayloadAction<Array<CircleUser>>) => {
      let users;
      if(state.users) {
        users = state.users.concat(action.payload)
      } else {
        users = action.payload;
      }
      users.sort((a, b) => a.username < b.username ? -1 : 1);
      state.users = users;
    },
    removeUser: (state, action: PayloadAction<CircleUser>) => {
      if(state.users) {
        state.users = state.users.filter(u => u.id !== action.payload.id);
      }
    },
    updateUser: (state, action: PayloadAction<CircleUser>) => {
      if(state.users) {
        state.users = state.users.map(u => {
          if(u.id === action.payload.id) {
            return {
              ...u,
              role: action.payload.role
            }
          } 
          return u;
        })
      }
    }
  },
});

/**
 * 
 * @param circleId 
 */
const loadUsers = (circleId: string): AppThunkAction => {
  return async(dispatch) => {
    const users = await circleUserService.findAll(circleId);
    await dispatch(circleUserSlice.actions.loadUsers(users));
  };
};

/**
 * 
 * @param circleId 
 * @param users 
 */
const addUsers = (circleId: string, users: Array<CircleUser>): AppThunkAction => {
  return async(dispatch) => {
    await circleUserService.addUsers(circleId, users);
    await dispatch(circleUserSlice.actions.addUsers(users));
  };
};

/**
 * 
 * @param circleId 
 * @param users 
 */
 const removeUser = (circleId: string, user: CircleUser): AppThunkAction => {
  return async(dispatch) => {
    await circleUserService.removeUser(circleId, user.id);
    await dispatch(circleUserSlice.actions.removeUser(user));
  };
};

/**
 * 
 * @param circleId 
 * @param user 
 */
const updateUser = (circleId: string, user: CircleUser): AppThunkAction => {
  return async(dispatch) => {
    await circleUserService.updateUser(circleId, user.id, user.role);
    await dispatch(circleUserSlice.actions.updateUser(user));
  };
};


export const circleUserSelector = (state: { circleUser: CircleUserState }) => state.circleUser;

export const reducer = circleUserSlice.reducer;

const slice = {
  addUsers,
  removeUser,
  loadUsers,
  updateUser,
  update: circleUserSlice.actions.update,
  resetCircleUserState: circleUserSlice.actions.resetCircleUserState
};

export default slice;