
export interface InstagramPost {
    id: string
    caption: string
    mediaType: MediaType
    mediaUrl: string
    creationDate: string,
    added: boolean
    thumbnailUrl: string
}

export enum MediaType {
    IMAGE = "IMAGE",
    CAROUSEL_ALBUM = "CAROUSEL_ALBUM",
    VIDEO = "VIDEO"
}

export interface InstagramAccount {
    id: string
    label: string
}