import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, generatePath } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { useAppDispatch } from "@app/hooks";
import Paths from "@app/routes";

import { successChannel } from '@channels/globalNotificationChannel'

import { Content } from "@components/Content"
import { Header } from "@components/Header"
import { CaptureCameraButton } from "@components/CaptureCameraButton";
import { SelectGalleryPictureButton } from "@components/SelectGalleryPictureButton";

import { CircleSettingsForm, IFormInputs as ICircleSettingsInputs } from '@features/circle/components/CircleSettingsForm';
import { CircleUsersList } from "@features/circle/components/CircleUsersList";
import { CircleScope, CircleUser, CircleUserRole } from '@features/circle/models';
import { circleSlice } from '@features/circle/slices'

import { Contact } from "@features/contact/models";

interface IFormInputs extends ICircleSettingsInputs {
}

/**
 * 
 */
 interface Media {
    source: string
    file: File
}

export const CreateCircle = () => {
    const { t } = useTranslation('circle_creation');
    const { t: ct } = useTranslation('common');

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [circleIconMedia, setCircleIconMedia] = useState<Media | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    // final circle contact list
    const [circleUsers, setCircleUsers] = useState<Array<CircleUser>>([]);
    
    /**
     * 
     * @param event 
     */
    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0];

            const mediaToAdd = {
                file: file,
                source: URL.createObjectURL(file)
            };

            setCircleIconMedia(mediaToAdd);
        }
    };

    /**
     * 
     */
    const onDeleteCircleIconMedia = () => {
        setCircleIconMedia(null);
    };

    /**
     * 
     */
     const validationSchema = Yup.object({
        name: Yup.string().required(ct('input_required')),
        hideUsers: Yup.boolean().defined(),
        allowComments: Yup.boolean().defined(),
        allowReactions: Yup.boolean().defined(),
        scope: Yup.mixed<CircleScope>().oneOf(Object.values(CircleScope)).defined()
    });

    /**
     * 
     */
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            hideUsers: true,
            allowComments: true,
            allowReactions: true,
            scope: CircleScope.PRIVATE
        }
    });

    /**
     * 
     * @param data 
     */
    const onSubmit = async (data: IFormInputs) => {
        setLoading(true);
        const circle = await dispatch(circleSlice.createCircle(data.name, circleUsers, {
            hideUsers: data.hideUsers,
            allowComments: data.allowComments,
            allowReactions: data.allowReactions,
            scope: data.scope
        }, circleIconMedia?.file));
        successChannel.emit(t('notification_create_success'));
        navigate(generatePath(Paths.Authenticated.circle, {"circleId": circle.id}));
    }

    /**
     * 
     * @param users 
     */
    const addUsers = (users: Array<Contact>) => {
        const updatedUsers = circleUsers.concat(users.map(u => {
            return {
                id: u.userId,
                username: u.username,
                picture: true,
                role: CircleUserRole.READER
            }
        }));
        updatedUsers.sort((a, b) => a.username < b.username ? -1 : 1);

        setCircleUsers(updatedUsers);
    }

    /**
     * 
     * @param user 
     */
    const removeUser = (user: CircleUser) => {
        setCircleUsers(circleUsers.filter(u => u.id !== user.id));
    }

    /**
     * 
     * @param user 
     */
    const updateUser = (user: CircleUser) => {
        setCircleUsers(circleUsers.filter(u => u.id === user.id).map(u => {return {...u, role: user.role}}));
    };

    return (
       <>
        <Header>
            <Typography variant="h2">{t('page_title')}</Typography>
        </Header>
        <Content>
            <Box sx={{ p: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
                    border: 'dashed',
                    borderWidth: 4,
                    borderColor: '#b1b1b1',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    height: '200px',
                    position: 'relative'
                }}>

                    {circleIconMedia &&
                        <Box sx={{ display: "flex", justifyContent: "center", height: '100%', p: 1, position: 'relative' }}>
                            <img src={circleIconMedia.source} alt={"snap"} style={{ maxHeight: '100%', width: 'auto' }} />
                        </Box>
                    }

                    {circleIconMedia &&
                        <IconButton
                            color="error"
                            component="span"
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0
                            }}
                            onClick={onDeleteCircleIconMedia}>
                            <DeleteIcon fontSize="large" />
                        </IconButton>
                    }

                    {!circleIconMedia && <>
                        <Box>
                            <Typography>{t('circle_icon')}</Typography>
                        </Box>
                        <Box>
                            {isMobile && <CaptureCameraButton handleCapture={handleCapture} />}
                            <SelectGalleryPictureButton handleCapture={handleCapture} />
                        </Box>
                    </>}
                </Box>

                <CircleSettingsForm control={control} errors={errors}/>

                <CircleUsersList users={circleUsers} addUsers={addUsers} removeUser={removeUser} updateUser={updateUser} title={t('contact_section_title')} maxHeight="300px"/>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2 }}>
                    <LoadingButton loading={loading} variant="outlined" color="primary" type="submit" >
                        {ct('validate_button')}
                    </LoadingButton>
                </Box>
            </Box>
        </Content>
       </>
    )
}