import { Navigate, Outlet } from 'react-router-dom'

import { useAppSelector } from "@app/hooks";
import Paths from '@app/routes';

import { userSelector } from '@features/user/slices';

const PublicRoutes = (props: any) => {
    const { isLoggedIn } = useAppSelector(userSelector);
    return isLoggedIn ? <Navigate to={Paths.Authenticated.home} replace={true}/> : <Outlet />
}

export default PublicRoutes;