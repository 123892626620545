import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { LazyScrollingContent } from "@components/LazyScrollingContent";

import { commonSelector } from "@features/common/slices/commonSlice";

import { computeThumbnailPostSize } from "@features/post/helpers/post.helper";
import { ThumbnailPost } from "@features/post/components/ThumbnailPost";

import { CirclePost } from '@features/circle/models';
import { circleSelector } from "@features/circle/slices";
import { circlePostService } from "@features/circle/services";
import { LoadOrder } from '@features/circle/services/circlePostService';
import { ViewCirclePostDialog } from "../components/ViewCirclePostDialog";

export const ViewAllCirclePosts = () => {
    const { t } = useTranslation('circle_post')
    const { circle } = useAppSelector(circleSelector);
    const { containerSize } = useAppSelector(commonSelector);
    const navigate = useNavigate();
    const { postId } = useParams();

    const [posts, setPosts] = useState<Array<CirclePost> | null>(null);
    const [postSize, setPostSize] = useState<number>(0);
    const [hasOlderPosts, setHasOlderPosts] = useState<boolean>(true);
    const [ openPostDialog, setOpenPostDialog ] = useState<boolean>(false);
    const [ selectedPost, setSelectedPost ] = useState<CirclePost>();
    
    /**
     * 
     * @param loadingPage 
     * @returns 
     */
    const loadPosts = async () => {
        if(!circle) {
            return;
        }

        const {postPerRow, postSize } = computeThumbnailPostSize(containerSize);
        const postPerColumn = Math.ceil(containerSize.height / postSize) + 1; // to ensure we'll load more posts to fill the page
        const nbPostToLoad = postPerColumn * postPerRow;
        setPostSize(postSize);
        
        if(posts) {
            const {posts: loadedPosts, hasOlderPosts} =  await circlePostService.loadPosts(circle.id, posts[posts.length - 1].id, LoadOrder.MORE_RECENT, nbPostToLoad);
            setPosts(posts.concat(loadedPosts));
            setHasOlderPosts(hasOlderPosts);
        } 
        // first load
        else {
            const {posts, hasOlderPosts} = await circlePostService.loadLastPosts(circle.id, nbPostToLoad);
            setHasOlderPosts(hasOlderPosts);
            setPosts(posts);
        }
    };
    
    /**
     * 
     * @param post 
     */
    const displayPost = (post: CirclePost) => {
        navigate(generatePath(Paths.Authenticated.circleViewPosts, {"circleId": circle!.id, "postId": post.id}))
    };

    useEffect(() => {
        if(postId && posts) {
            const post = posts.find(p => p.id === postId);
            if(post) {
                setSelectedPost(post);
                setOpenPostDialog(true);
                return;
            }
        }
        setOpenPostDialog(false);
    }, [postId, posts]);

    /**
     * 
     */
    const closeDialog = () => {
        navigate(generatePath(Paths.Authenticated.circleViewAllPosts, {"circleId": circle!.id}))
        setOpenPostDialog(false);
    }

    if(!circle) {
        return (<></>);
    }

    return (
        <>
            <LazyScrollingContent height='100%' items={posts} hasOlderItems={hasOlderPosts} loadNext={loadPosts} noElementText={t('no_post')} 
            render={(items: Array<CirclePost>) => 
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                    {items.map(post => <ThumbnailPost post={post} size={postSize} key={post.id} onClick={() => displayPost(post)}/>)}
                </div>
            }/>
            
            {selectedPost && <ViewCirclePostDialog post={selectedPost} open={openPostDialog} onClose={closeDialog} />}
        </>
    )
};