const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export const convertToReadableDate = (dateValue: string): string => {
    const date = new Date(dateValue);
    return date.toLocaleDateString(undefined, options)
};

export interface DurationParseResult {
    hours: number
    minutes: number
    seconds: number
}

export const parseDuration = (totalSeconds: number): DurationParseResult => {
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = Math.round(totalSeconds % 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return {
        hours,
        minutes,
        seconds
    }
}

export const formatduration = (totalSeconds: number): string => {
    const duration = parseDuration(totalSeconds);
    return `${duration.minutes.toString().padStart(2, '0')}:${duration.seconds.toString().padStart(2, '0')}`
};