import React, { ForwardedRef } from "react";
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

interface AddFabButtonProps {
    onClick: () => void
}

export const AddFabButton = React.forwardRef((props: AddFabButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {

    return (
        <Fab {...props} color="primary" aria-label="add" sx={{
                position: 'fixed',
                bottom: 16,
                right: 16
            }}
            onClick={props.onClick}
            ref={ref}>
            <AddIcon />
        </Fab>
    );
});