import { PropTypes, IconButton, SxProps } from "@mui/material";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { Theme } from "@emotion/react";

interface SelectGalleryPictureButtonProps {
    handleCapture: Function
    multiple ?: boolean
    sx ?: SxProps<Theme>
}

export const SelectGalleryPictureButton = (props: SelectGalleryPictureButtonProps) => {

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleCapture(event);
    }
    
    return (
        <>
            <input
                accept="image/*, video/*"
                id="inputfile-gallery-picture"
                type="file"
                onChange={handleCapture}
                style={{display:'none'}}
                {...(props.multiple && {multiple:true})}
            />
            <label htmlFor="inputfile-gallery-picture">
                <IconButton
                    sx={props.sx ? props.sx : {}}
                    aria-label="upload picture"
                    component="span">
                    <PhotoLibraryIcon fontSize="large" />
                </IconButton>
            </label>
        </>
    );
};