

export const buildQueryParams = (entries: {[key: string]: any}): string => {
    let queryParams = '';
    for (const [k, v] of Object.entries(entries)) {
        if(v) {
            if(queryParams === '') {
                queryParams += '?';
            } else {
                queryParams += '&';
            }
            queryParams += `${k}=${v}`;
        }
    }
    return queryParams;
};