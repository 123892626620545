import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@app/hooks';

import { commonSlice } from '@features/common/slices';

import { appBarHeight } from '@layouts/AuthenticatedLayout';

export const EmptyCircle = () => {
    const { t } = useTranslation('circle');
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(commonSlice.openDrawer());
    }, []);

    return (
        <div style={{
            height:`calc(100% - ${appBarHeight}px)`, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <p>{t('no_circle_selected')}</p>
        </div>
    )
}