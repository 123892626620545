import Paths from "@app/routes";
import { INVITATIONS_TAB } from "@features/contact/pages/ViewContactsAndInvitations";
import { NotificationCode } from "@features/user/models";
import { generatePath } from "react-router-dom";

interface NotificationHandler {
    targetRoute ?: string
}

const handlers: Map<NotificationCode, NotificationHandler> = new Map([
   [NotificationCode.RECEIVED_CONTACT_INVITATION, {
    targetRoute : generatePath(Paths.Authenticated.manageContacts, {tab: INVITATIONS_TAB})
   }] 
]);

export default handlers;