import { useAppSelector } from "@app/hooks";
import { userSelector } from "@features/user/slices";
import { Avatar, SxProps, Theme, Tooltip } from "@mui/material"
import { useEffect, useState } from "react";

import userIcon from './assets/user-avatar/icon-256.png';

interface UserAvatarProps {
    user: {id: string, username: string, picture: boolean}
    sx ?: SxProps<Theme>
}

export const UserAvatar = (props: UserAvatarProps) => {
    const { user } = props;
    const { user: currentUser, pictureToken } = useAppSelector(userSelector);
    const [ pictureUrl, setPictureUrl ] = useState<string>(userIcon);
    const [avatarProps, setAvatarProps ] = useState<SxProps<Theme> | undefined>({background:'#bdbdbd', ...props.sx});

    useEffect(() => {
        if(user.picture) {
            if(currentUser!.id === user.id) {
                setPictureUrl(`/api/users/me/picture?token=${pictureToken}`);
            } else {
                setPictureUrl(`/api/users/${user.id}/picture`);
            }
            setAvatarProps(props.sx);
        } else {
            setPictureUrl(userIcon);
        }
    }, [pictureToken]);

    return (<>
        {currentUser!.id === user.id && 
            <Avatar 
                sx={avatarProps}
                src={pictureUrl}>
            </Avatar>
        }
        {currentUser!.id !== user.id && 
            <Tooltip title={user.username.charAt(0).toUpperCase()}>
                <Avatar 
                    sx={avatarProps}
                    src={pictureUrl}>
                </Avatar>
            </Tooltip>
        }
    </>);
};