import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "@app/hooks";
import notificationService from '@features/user/services/notificationService';
import { Notification, NotificationActionType } from "@features/user/models";
import circleSlice from '@features/circle/slices/circleSlice';
import contactSlice from '@features/contact/slices/contactSlice';

export interface NotificationState {
  unread: number
}

const initialState: NotificationState = {
  unread: 0
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    markAllRead: (state) => {
      state.unread = 0;
    },
    setUnreadNotifications: (state, action: PayloadAction<number>) => {
      state.unread = action.payload;
    }
  },
});

/**
 * 
 * @returns 
 */
const markAllRead = (): AppThunkAction => {
  return async (dispatch) => {
    await notificationService.markAllRead();
    dispatch(notificationSlice.actions.markAllRead());
  }
};

/**
 * 
 * @returns 
 */
const checkUnreadNotifications = (): AppThunkAction => {
  return async (dispatch) => {
    const nbUnreadNotifications = await notificationService.getUnreadNotifications();
    dispatch(notificationSlice.actions.setUnreadNotifications(nbUnreadNotifications));
  };
};

/**
 * 
 */
 const acceptNotification = (notification: Notification): AppThunkAction => {
  return async (dispatch) => {
    await notificationService.acceptNotification(notification.id);
    // special case -> reload all circles to refresh the menu
    if(notification.action) {
      switch(notification.action.type) {
        case NotificationActionType.CIRCLE_INVITATION: 
          dispatch(circleSlice.loadCircles());
          break;
        case NotificationActionType.CONTACT_INVITATION: 
          dispatch(contactSlice.resetState());
          break;
      }
    }
  };
};

/**
 * 
 */
const refuseNotification = (notification: Notification): AppThunkAction => {
  return async (dispatch) => {
    await notificationService.refuseNotification(notification.id);
  };
};

export const notificationSelector = (state: { notification: NotificationState }) => state.notification;

export const reducer = notificationSlice.reducer;

const slice = {
  markAllRead,
  checkUnreadNotifications,
  acceptNotification,
  refuseNotification
};

export default slice;