import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from "@app/hooks";
import Paths from '@app/routes';

import { userSlice } from '@features/user/slices';

import { userService } from '@features/user/services';

import { translate } from '@app/i18n';

/**
 * 
 */
interface IFormInputs {
  username: string
  email: string
  password: string
  confirmPassword: string
}

/**
 * 
 */
const initialValues: IFormInputs = {
  username: "",
  email: "",
  password: "",
  confirmPassword: ""
};

export const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('signup');
  const { t: at } = useTranslation('account_form');

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * 
   * @returns 
   */
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  /**
   * 
   * @param event 
   */
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /**
   * 
   */
  const validationSchema = Yup.object().shape({
    username: Yup.string()
        .required(at('input_username_error_required'))
        .test(
            'username-backend-validation',
            async (username, testContext) => {
              // need to test it first because triggered at every validation
              if(username && username.length > 0) {
                const {success, err} = await userService.validateUsername(username);
                if(!success) {
                  return testContext.createError({
                    message: err ? err : translate('errors', 'unexpected')
                  });
                }
              }
              return true;
            }
          ),
    email: Yup.string()
        .email(at('input_email_error_format'))
        .required(at('input_email_error_required'))
        .test(
            'email-backend-validation',
            at('input_email_error_exists'), 
            async (email, testContext) => {
              // need to test it first because triggered at every validation
              if(email && email.length > 0) {
                const {success, err} = await userService.validateEmail(email);
                if(!success) {
                  return testContext.createError({
                    message: err ? err : translate('errors', 'unexpected')
                  })
                }
              }
              return true;
            }
          ),
    password: Yup.string().required(at('input_password_error_required')),
    confirmPassword: Yup.string().required(at('input_confirm_password_error_required')).oneOf([Yup.ref('password'), ''], at('input_confirm_password_error_nomatch'))
  });

  /**
   * 
   */
  const { control, handleSubmit, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: initialValues
  });

  /**
   * 
   * @param data 
   */
  const onSubmit = async (data: IFormInputs) => {
    const { username, email, password } = data;
    setLoading(true);

    try {
      await dispatch(userSlice.signup(username, email, password));
      navigate(Paths.Public.signin);
    } catch (err){}
    finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      component="form"
      noValidate
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 56px)"
    >
        <FormControl sx={{ m: 1, width: '25ch' }} error={errors.username ? true : false}>
          <InputLabel htmlFor="form-username">{at('input_username_label')}</InputLabel>
          <Controller
            name="username"
            control={control}
            render={({ field }) => <OutlinedInput {...field} 
              id="form-username"
              label={at('input_username_label')}
              aria-describedby="form-username-error-text" />} />
              <FormHelperText id="form-username-error-text">{errors.username?.message}</FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, width: '25ch' }} error={errors.email ? true : false}>
          <InputLabel htmlFor="form-username">{at('input_email_label')}</InputLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <OutlinedInput {...field} 
              id="form-email"
              label={at('input_email_label')}
              aria-describedby="form-email-error-text" />} />
              <FormHelperText id="form-email-error-text">{errors.email?.message}</FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, width: '25ch' }} error={errors.password ? true : false}>
            <InputLabel htmlFor="form-password">{at('input_password_label')}</InputLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => <OutlinedInput {...field} 
                id="form-password"
                type={showPassword ? 'text' : 'password'}
                label={at('input_password_label')}
                aria-describedby="form-password-error-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment> } 
                />} />
                <FormHelperText id="form-password-error-text">{errors.password?.message}</FormHelperText>
            </FormControl>

            <FormControl sx={{ m: 1, width: '25ch' }} error={errors.confirmPassword ? true : false}>
                <InputLabel htmlFor="form-password">{at('input_confirm_password_label')}</InputLabel>
                <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => <OutlinedInput {...field} 
                    id="form-confirm-password"
                    type="password"
                    label={at('input_confirm_password_label')}
                    aria-describedby="form-confirm-password-error-text" />} />
                <FormHelperText id="form-confirm-password-error-text">{errors.confirmPassword?.message}</FormHelperText>
            </FormControl>

          <LoadingButton loading={loading} variant="outlined" type="submit">{at('button_signup')}</LoadingButton>
    </Stack >
  );
}

export default SignUp;