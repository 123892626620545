import { FormControl, FormControlLabel, FormHelperText, MenuItem, Select, Switch, TextField } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CircleScope } from "@features/circle/models";

/**
 * 
 */
 export interface IFormInputs {
    hideUsers: boolean
    allowComments: boolean
    allowReactions: boolean
    name: string
    scope: CircleScope
}

interface CircleSettingsFormProps {
    control: Control<IFormInputs>,
    errors: FieldErrors<IFormInputs>
}

export const CircleSettingsForm = (props: CircleSettingsFormProps) => {
    const { t } = useTranslation('circle_settings');
    const { t: cct } = useTranslation('circle');
    const { control , errors} = props;

    return (
        <FormControl sx={{ width: '100%', my: 2 }}>

            <Controller
                name="name"
                control={control}
                render={({ field }) => <TextField {...field}
                    label={t('input_name_label')} />
                } />
            <FormHelperText sx={{color: "error.main"}}>{errors.name?.message}</FormHelperText>

            <FormControlLabel
                control={<Controller
                    name="hideUsers"
                    control={control}
                    render={({ field }) => <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value || false} />
                    } />}
                label={t('switch_hideUsers')}
                labelPlacement="start"
                sx={{
                    mt: 2,
                    "& .MuiFormControlLabel-label": {
                        flexGrow: 1
                    }
                }} />

            <FormControlLabel
                control={<Controller
                    name="allowComments"
                    control={control}
                    render={({ field }) => <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value || false} />
                    } />}
                label={t('switch_allowComments')}
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label": {
                        flexGrow: 1
                    }
                }} />

            <FormControlLabel
                control={<Controller
                    name="allowReactions"
                    control={control}
                    render={({ field }) => <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value || false} />
                    } />}
                label={t('switch_allowReactions')}
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label": {
                        flexGrow: 1
                    },
                    mb: 2
                }} />

            <FormControlLabel
                control={<Controller
                    name="scope"
                    control={control}
                    render={({ field }) => (
                        <Select {...field}>
                            <MenuItem value={CircleScope.PUBLIC}>{cct('scope_public')}</MenuItem>
                            <MenuItem value={CircleScope.PRIVATE}>{cct('scope_private')}</MenuItem>
                        </Select>
                    )}
                />}
                label={t('select_scope')}
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label": {
                        flexGrow: 1
                    }
                }} />
        </FormControl>
    );
};