import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@app/hooks";
import Paths from "@app/routes";

import { Content } from "@components/Content";
import { Header } from "@components/Header";
import { InstagramPostSelection } from "../components/InstagramPostSelection";



export const ViewInstagramPosts = () => {
    const { t } = useTranslation('view_instagram_posts');
    const navigate = useNavigate();
    
    const onBack = () => {
        navigate(Paths.Authenticated.posts);
    }

    return (
        <>
            <Header onBack={onBack}>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                <InstagramPostSelection />
            </Content>
        </>
    );
}