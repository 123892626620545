import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Dialog, IconButton, Slide } from '@mui/material';

import { CirclePostViewer } from '@features/circle/components/CirclePostViewer';
import { CirclePost } from '@features/circle/models';



const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="right" ref={ref} {...props} />;
  });

interface ViewCirclePostDialogProps {
    post: CirclePost
    open: boolean
    onClose: () => void
}


export const ViewCirclePostDialog = (props: ViewCirclePostDialogProps) => {
    const closeDialog = () => {
        if(props.onClose) {
            props.onClose();
        }
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={closeDialog}
            TransitionComponent={Transition}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        backgroundColor: "black",  // Set your width here
                    },
                },
            }}>
            <Box sx={{display:'flex', justifyContent: 'flex-end'}}>
                <IconButton size="large" onClick={closeDialog}>
                    <CloseIcon sx={{color:'white'}} fontSize="inherit"/>
                </IconButton>
            </Box>
            <CirclePostViewer post={props.post} displayMenu={false} onOpenMenu={(event: React.MouseEvent<HTMLElement>) => {}}/>
        </Dialog>
    )
}