import { CSSProperties } from 'react'
import icon64 from './assets/sprinkl-icon/icon-64.png'

interface SprinklIconProps {
    style ?: CSSProperties
}


const SprinklIcon = (props: SprinklIconProps) => {

    return (
        <img src={icon64} style={props.style} />
    );
};

export default SprinklIcon;