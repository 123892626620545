import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { Box, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { debounce } from '@mui/material/utils';
import { useConfirm } from 'material-ui-confirm';
import DeleteIcon from '@mui/icons-material/Delete';
import { generatePath, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from '@app/hooks';

import { Loading } from "@components/Loading";
import { AddFabButton } from '@components/AddFabButton';

import { CirclePostViewer } from "@features/circle/components/CirclePostViewer";
import { CircleTabs } from '@features/circle/models/circleNavigation';
import { CircleAuthorization, CirclePostAuthorization, CirclePost } from '@features/circle/models';
import { circlePostSlice, circlePostSelector, circleSelector } from '@features/circle/slices'
import { circleUserService } from '@features/circle/services';

// will be updated to be the post container bloc height
let srollLoadingThresold = 100;

/**
 * 
 */
let observedUnreadPost  = new Array<{post: CirclePost, elmt: HTMLElement}>();
let lastVisiblePost: CirclePost | null = null;


enum FetchingType {
    OLDER,
    MORE_RECENT,
    NONE
}

export const ViewCirclePosts = () => {
    const { t } = useTranslation('circle_post');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const confirm = useConfirm();

    const [fetchingType, setFetchingType] = useState(FetchingType.NONE);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const { posts, loadedCircleId, scrollTop, hasMoreRecentPosts, hasOlderPosts } = useAppSelector(circlePostSelector);
    const { circle } = useAppSelector(circleSelector);

    const [ intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver>();
    const [ delayIntersetionObserverAfterScroll, setDelayIntersetionObserverAfterScroll ] = useState<boolean>(false);

    const [anchorElPostMenu, setAnchorElPostMenu] = React.useState<null | HTMLElement>(null);

    const [ selectedMenuPost, setSelectedMenuPost ] = React.useState<CirclePost | null>(null);


    const [postsContainerRef, setPostsContainerRef] = useState<HTMLElement>();
    const setPostsContainerRefCallback = useCallback((node: HTMLElement) => {
        // if there was a previously defined container ref
        if(postsContainerRef) {
            // disconnect all observe, just in case
            if(intersectionObserver) {
                intersectionObserver.disconnect();
            }

            srollLoadingThresold = postsContainerRef.offsetHeight;
        }
        if(node) {
            setPostsContainerRef(node);
        }
      }, []);




    useEffect(() => {
        return function cleanUp() {
            lastVisiblePost = null;
            observedUnreadPost = [];
        }
    }, []);

    /**
     * Called on initial load to set the scroll on the right post (last one read by the user)
     */
    useEffect(() => {
        if (scrollTop > 0 && postsContainerRef) {
            postsContainerRef.scrollTo({ top: scrollTop - postsContainerRef.offsetTop });
            setDelayIntersetionObserverAfterScroll(false);
        }
    }, [scrollTop, postsContainerRef]);


    /**
     * Initial load - executed when selecting a circle
     * Load posts & set focus on last read posts if defined
     */
    useEffect(() => {
        (async () => {
            if (circle != null && loadedCircleId !== circle.id) {
                setIsInitialLoad(true);
                const posts = await dispatch(circlePostSlice.loadPostsFromLastRead(circle.id));

                // check if there is a post that should be focused on initial load
                // if so, delay the intersection observer until everything is fully initialized
                setDelayIntersetionObserverAfterScroll(posts.find(p => p.focused) ? true : false);
                setIsInitialLoad(false);
            } else {
                setIsInitialLoad(false);
            }
        })();
        
    }, [circle]);

    /**
     * Build the intersection observer used to check whenever a post is fully visible or not
     */
    useEffect(() => {
        if (postsContainerRef) {
            setIntersectionObserver(
                new IntersectionObserver(function(entries) {
                    if(entries[0].isIntersecting === true) {
                        for(const r of observedUnreadPost) {
                            if(r.elmt === entries[0].target) {     
                                if(isPostMoreRecentThanLastVisible(r.post)) {
                                    lastVisiblePost = r.post;
                                    setLastReadPost(r.post);
                                    cleanOlderObservedPost();
                                }
                            }
                        }
                    }


                }, { threshold: [1], root: postsContainerRef })
            );
        }

        return function cleanUp() {
            if(intersectionObserver) {
                intersectionObserver.disconnect();
            }
        }
    }, [postsContainerRef]);


    useEffect(() => {
        if(intersectionObserver && !delayIntersetionObserverAfterScroll) {
            if(observedUnreadPost.length > 0) {
                for(let r of observedUnreadPost) {
                    observePost(r);
                }
            }
        }
    }, [delayIntersetionObserverAfterScroll, intersectionObserver])


    /**
     * Load more posts
     */
    useEffect(() => {
        (async () => {
            switch(fetchingType) {
                case FetchingType.NONE: return;
                case FetchingType.MORE_RECENT: {
                    await dispatch(circlePostSlice.loadMoreRecentPosts(loadedCircleId, posts[0].id));
                    setFetchingType(FetchingType.NONE);
                    break;
                }
                case FetchingType.OLDER: {
                    await dispatch(circlePostSlice.loadOlderPosts(loadedCircleId, posts[posts.length - 1].id));
                    setFetchingType(FetchingType.NONE);    
                    break;
                }
            }
        })();
    }, [fetchingType]);

    /**
     * 
     * @param event 
     * @returns 
     */
    const onCircleContentScroll = (event: React.UIEvent<HTMLElement>) => {
        if (fetchingType !== FetchingType.NONE) {
            return;
        }
        const scrollTop = event.currentTarget.scrollTop;

        if (scrollTop < srollLoadingThresold) {
            if(hasMoreRecentPosts) {
                setFetchingType(FetchingType.MORE_RECENT);
            } else {
                setFetchingType(FetchingType.NONE);
            }
        } else {
            const listHeight = (event.currentTarget.children[0] as HTMLElement).offsetHeight;
            if (scrollTop + event.currentTarget.offsetHeight > (listHeight - srollLoadingThresold)) {
                if(hasOlderPosts) {
                    setFetchingType(FetchingType.OLDER);
                } else {
                    setFetchingType(FetchingType.NONE);
                }
            }
        }
    }

    /**
     * Helper that checks if a given post is more recent that the last visible 
     * @param post 
     * @returns 
     */
    const isPostMoreRecentThanLastVisible = (post: CirclePost) => {
        if(lastVisiblePost) {
            const lastVisiblePostDate = new Date(lastVisiblePost.date);
            const postDate = new Date(post.date);
            return postDate.getTime() > lastVisiblePostDate.getTime();
        }
        return true;
    };

    /**
     * Debounce to avoid updating the last read post for every single one of them
     */
    const setLastReadPost = React.useMemo(
        () =>
            debounce(
                async (
                    post: CirclePost
                ) => {
                    if(circle) {
                        await circleUserService.updateLastReadPost(circle.id, post.id);
                    }
                },
                3000,
            ),
        [],
    );

    /**
     * Called when rendering a post element to add it as ref
     * @param post 
     * @param ref 
     */
    const addPostObserver = (post: CirclePost, ref: HTMLElement) => {
        if(ref) {
            // if the last visible post is not defined or the given post is more recent, observe it
            if(!lastVisiblePost || isPostMoreRecentThanLastVisible(post)) {
                const existingRef = observedUnreadPost.find(r => r.post.id === post.id);

                // to handle re-rendering 
                if(existingRef) {
                    intersectionObserver?.unobserve(existingRef.elmt);
                    existingRef.elmt = ref;
                    intersectionObserver?.observe(ref);
                } else {
                    // no last visible post set for now, try to find it to initialize it
                    // no need to observe it since it's already the last visible one
                    if(lastVisiblePost == null && circle && circle.lastReadPostId && post.id === circle.lastReadPostId) {
                        lastVisiblePost = post;
                    } else {
                        const postRef = {
                            post,
                            elmt: ref
                        };
                        observedUnreadPost.push(postRef);
                        
                        if(!delayIntersetionObserverAfterScroll && intersectionObserver) {
                            observePost(postRef);
                        }
                    }
                }
            }
        }
    };

    /**
     * 
     * @param postRef 
     */
    const observePost = (postRef: {post: CirclePost, elmt: HTMLElement}) => {
        if(intersectionObserver) {
            intersectionObserver.observe(postRef.elmt);
        }
    };

    /**
     * After adding a new last visible post, clean posts that are older
     */
    const cleanOlderObservedPost = () => {
        const postToUnobserve = observedUnreadPost.filter(r => !isPostMoreRecentThanLastVisible(r.post));
        postToUnobserve.forEach(v => {
            intersectionObserver?.unobserve(v.elmt);
        });
        observedUnreadPost = observedUnreadPost.filter(r => !postToUnobserve.includes(r))
    };

    /**
     * 
     */
    const onAddPostClick = () => {
        navigate(generatePath(CircleTabs.ADD_POST.route, {'circleId': loadedCircleId}));
    };

    /**
     * 
     */
    const removePostFromCircle = async () => {
        if(selectedMenuPost && circle) {
            try {
                await confirm({description: t('confirm_remove_post_content', {name: circle.name})}); 
                await dispatch(circlePostSlice.removePostFromCircle(circle.id, selectedMenuPost.id));
            } catch(err){}
        }
    }


    if (isInitialLoad) {
        return (
            <>
                <Loading />
            </>
        );
    } else {
        if (posts.length === 0) {
            return (
                <div style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <p>{t('no_post')}</p>
                    {circle?.authorizations.includes(CircleAuthorization.ADD_POST) && 
                        <Tooltip open={true} title={t('tooltip_addpost')} arrow placement="left-end">
                            <AddFabButton onClick={onAddPostClick}/>
                        </Tooltip>
                    }
                </div>
            );
        } else {
            

            return (
                <>
                    <Box sx={{
                        height: '100%',
                        overflowY: 'auto'
                    }}
                        onScroll={onCircleContentScroll}
                        ref={setPostsContainerRefCallback}>
                        <div>
                            {posts.map((post, index) => {
                                const canDeletePost = circle?.authorizations.includes(CircleAuthorization.REMOVE_POST) || post.authorizations.includes(CirclePostAuthorization.DELETE_POST);

                                return (
                                    <CirclePostViewer post={post} 
                                        key={post.id} 
                                        ref={(el: HTMLElement) => {
                                            addPostObserver(post, el);
                                        }}
                                        onOpenMenu={(event: React.MouseEvent<HTMLElement>) => {
                                            setAnchorElPostMenu(event.currentTarget);
                                            setSelectedMenuPost(post);
                                        }}
                                        displayMenu={canDeletePost}
                                    />
                                )
                            })}
                        </div>
                        {circle?.authorizations.includes(CircleAuthorization.ADD_POST) && 
                            <AddFabButton onClick={onAddPostClick}/>
                        }
                    </Box>

                    <Menu
                        anchorEl={anchorElPostMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElPostMenu)}
                        onClose={() => setAnchorElPostMenu(null)}
                        onClick={() => setAnchorElPostMenu(null)}
                        transitionDuration={{exit: 0}}>
                            <MenuItem onClick={() => removePostFromCircle()}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                {t('remove_post_button')}
                            </MenuItem>
                    </Menu>
                </>
            );
        }
    }
}