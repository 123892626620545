import axios from '@app/axios';
import { Post, SelectablePost, PostCircleOverview, VideoMetadata } from '@features/post/models';

interface LoadPostsData<T> {
    hasNext: boolean
    posts: Array<T>
}

/**
 * Load user's posts by page
 * @param page 
 * @returns 
 */
const loadPosts = async (page: number = 0): Promise<LoadPostsData<Post>> => {
    const { data } = await axios.get(`/posts?page=${page}`);
    return data;
};

/**
 * Load all posts for a user + check if part of the given circle to select it 
 * @param page 
 * @param circleId 
 */
const loadPostsForCircle = async (page: number = 0, circleId: string): Promise<LoadPostsData<SelectablePost>> => {
    const { data } = await axios.get(`/posts?page=${page}&circleId=${circleId}`);
    return data;
}

/**
 * 
 * @param id 
 * @returns 
 */
const getById = async (id: string): Promise<Post> => {
    const { data } = await axios.get(`/posts/${id}`);
    return data;
};

/**
 * 
 * @param description 
 */
const createPost = async (description: string | null) => {
    const { data } = await axios.post('/posts', {
        description
    });
    return data;
};

/**
 * 
 * @param postId 
 * @param picture 
 * @returns 
 */
const addPictureToPost = async(postId: string, picture: Blob) => {
    const formData = new FormData();
    formData.append("picture", picture, `photo-${new Date().toISOString()}`);

    await axios.post(`/posts/${postId}/medias/pictures`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
};

/**
 * 
 * @param postId 
 * @param video 
 * @returns 
 */
const addVideoToPost = async(postId: string, video: Blob, thumbnail: Blob, metadata: VideoMetadata) => {
    const formData = new FormData();
    const videoName = `video-${new Date().toISOString()}`;
    formData.append("video", video, videoName);
    formData.append("thumbnail", thumbnail, `thumbnail-${videoName}`);
    if(metadata.duration) {
        formData.append("duration", '' + metadata.duration);
    }
    if(metadata.width) {
        formData.append("width", '' + metadata.width);
    }
    if(metadata.height) {
        formData.append("height", '' + metadata.height);
    }

    await axios.post(`/posts/${postId}/medias/videos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
};

/**
 * 
 * @param postId 
 * @returns 
 */
const deletePost = async (postId: string) => {
    await axios.delete(`/posts/${postId}`);
};

/**
 * 
 * @param postIds 
 */
const deletePosts = async (postIds: string[]) => {
    await axios.post(`/posts/delete`, {
        postIds
    });
}

/**
 * 
 * @param post 
 */
const updatePost = async (postId: string, description: string) => {
    await axios.put(`/posts/${postId}`, {
        description: description
    });
};

/**
 * 
 * @param postId 
 */
const listCirclesUsingPost = async (postId: string): Promise<Array<PostCircleOverview>> => {
    const { data } = await axios.get(`/posts/${postId}/circles`);
    return data;
};

const service = {
    loadPosts,
    loadPostsForCircle,
    createPost,
    addPictureToPost,
    addVideoToPost,
    getById,
    deletePost,
    deletePosts,
    updatePost,
    listCirclesUsingPost
};

export default service;