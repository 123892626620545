import { Box, CircularProgress } from "@mui/material";

export const Loading = () => {

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'100%'}}>
                <CircularProgress sx={{mr: 1}}/> 
                <p>Loading...</p>
            </Box>
        </>
      );
};