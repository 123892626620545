
export interface Contact {
    userId: string, 
    username: string,
    picture: boolean
}

export interface SearchContact extends Contact {
    canBeAdded: boolean
    pendingInvitation: boolean
}

export enum ContactInvitationStatus {
    ACCEPTED = "ACCEPTED",
    PENDING = "PENDING",
    REFUSED = "REFUSED",
    CANCELLED = "CANCELLED"
}

export interface ContactInvitation {
    id: string
    status: ContactInvitationStatus,
    userLabel?: string
}