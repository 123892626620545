import axios from '@app/axios';
import { BusinessError } from '@app/errors';
import { AuthenticationData, User } from '@features/user/models';
import { truncate } from 'fs/promises';

/**
 * 
 * @returns 
 */
const getCurrentUser = async (): Promise<User> => {
    const { data } = await axios.get('/users/me');
    return data;
};

/**
 * 
 * @param username 
 */
 const validateUsername = async (username: string): Promise<{success: boolean, err ?: string}> => {
    try {
        await axios.post("/public/users/username/validate", { username: username });
        return {success: true};
    } catch(err) {
        if(err instanceof BusinessError) {
            return {success: false, err: err.message};
        }
        return {success: false};
    }
};

/**
 * 
 * @param email 
 */
const validateEmail = async (email: string): Promise<{success: boolean, err ?: string}> => {
    try {
        await axios.post("/public/users/email/validate", { email: email });
        return {success: true};
    } catch(err) {
        if(err instanceof BusinessError) {
            return {success: false, err: err.message};
        }
        return {success: false};
    }
}

/**
 * 
 * @param login 
 * @param password 
 * @returns 
 */
const authenticate = async (username: string, password: string): Promise<AuthenticationData> => {
    const { data } = await axios.post('/public/auth/signin', {username, password});
    return data;
}

/**
 * 
 */
const logout = async() => {
    await axios.post('/public/auth/logout');
}

/**
 * 
 * @param username 
 * @param login 
 * @param password 
 * @returns 
 */
const createUser = async (username: string, email: string, password: string) => {
    await axios.post("/public/users/signup", { 
        username,
        email,
        password 
    });
}

/**
 * 
 * @param username 
 */
const updateUsername = async(username: string) => {
    await axios.put('/users/me/username', {username});
}

/**
   * 
   * @param oldPassword 
   * @param newPassword 
   */
const updatePassword = async (oldPassword: string, newPassword: string): Promise<{success: boolean, err ?: string}> => {
    try {
        await axios.put('/auth/me/password', {
            oldPassword,
            newPassword
        });
        return {success: true};
    } catch(err) {
        if(err instanceof BusinessError) {
            return {success: false, err: err.message};
        }
        return {success: false};
    }
  }

/**
 * 
 * @param postId 
 * @param video 
 * @returns 
 */
const updateProfilePicture = async(picture: Blob) => {
    const formData = new FormData();
    formData.append("picture", picture, `profile-${new Date().toISOString()}`);
    
    await axios.put(`/users/me/picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
};

const deleteUserAccount = async() => {
    await axios.delete('/users/me');
}

const service = {
    getCurrentUser,
    createUser,
    authenticate,
    logout,
    validateEmail,
    validateUsername,
    updateUsername,
    updatePassword,
    updateProfilePicture,
    deleteUserAccount
};

export default service;