import { Header } from "@components/Header";
import { Content } from "@components/Content";
import { Typography, Box, Stack, FormControl, InputLabel, FormHelperText, OutlinedInput, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Avatar, useMediaQuery, useTheme, Select, FormControlLabel, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm,Resolver  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { UserAvatar } from "@components/icons/UserAvatar";
import { userSelector, userSlice } from "@features/user/slices";
import { userService } from "@features/user/services";
import { translate } from "@app/i18n";
import { successChannel } from "@app/channels/globalNotificationChannel";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SelectGalleryPictureButton } from "@components/SelectGalleryPictureButton";
import { dataURLtoFile } from '@helpers/file.helper';
import ReactCrop, { PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import CameraPhotoCapture from "@features/post/components/CameraPhotoCapture";
import useCameraDevice from "@hooks/useCameraDevice";
import debounce from 'debounce';
import { UserSettingsPostDisplay } from "../models";
import { useConfirm } from "material-ui-confirm";
  
  export const UserAccount = () => {
    const hasCameraDevice = useCameraDevice();
    const { user } = useAppSelector(userSelector);
    const confirm = useConfirm();
    const { t } = useTranslation('account');
    const {t: at} = useTranslation('account_form');
  
    const [showEditUsernameDialog, setShowEditUsernameDialog] = useState(false);
    const [showEditPasswordDialog, setShowEditPasswordDialog] = useState(false);
    const [showViewProfilePictureDialog, setShowViewProfilePictureDialog] = useState(false);
    const [ showCaptureCameraDialog, setShowCaptureCameraDialog ] = useState(false);
  
    const [profilePicture, setProfilePicture] = useState<string | null>(null);

    /**
     * 
     * @returns 
     */
    const openEditUsernameDialog = () => setShowEditUsernameDialog(true);
    const closeEditUsernameDialog = () => setShowEditUsernameDialog(false);

    const openEditPasswordDialog = () => setShowEditPasswordDialog(true);
    const closeEditPasswordDialog = () => setShowEditPasswordDialog(false);

    const openVewProfilePictureDialog = () => setShowViewProfilePictureDialog(true);
    const closeViewProfilePictureDialog = () => {
        setProfilePicture(null);
        setShowViewProfilePictureDialog(false);
    }

    const openCaptureCameraDialog = () => setShowCaptureCameraDialog(true);
    const closeCaptureCameraDialog = () => setShowCaptureCameraDialog(false);

    const handleMediaGallerySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = Array.from(event.target.files)[0];
            if(file.type.includes("image/")) {
                handlePictureCapture(file);
            }
        }
    };

    const handlePictureCapture = (file: File) => {
        setProfilePicture(URL.createObjectURL(file));
        openVewProfilePictureDialog();
    }

    const deleteAccount = async () => {
        try {
            await confirm({description: t('confirm_delete_account')});
            await userService.deleteUserAccount();
            successChannel.emit(t('notification_account_delete_success'));
            await userSlice.logout();
        } catch(err){}
    }

    return (
        <>
            <Header>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: 2}}>
                    <Box sx={{position: 'relative', flexBasis: '200px'}}>
                        <UserAvatar user={user!} sx={{width: '200px', height: '200px'}} />
                        <Box sx={{position:'absolute', bottom: 0, right: 0, display:'flex'}}>
                            <Avatar sx={{bgcolor: 'primary.main'}}>
                                <SelectGalleryPictureButton handleCapture={handleMediaGallerySelection} sx={{color: 'white'}}/>
                            </Avatar>
                            {hasCameraDevice && 
                                <Avatar sx={{bgcolor: 'primary.main', ml: 1}} onClick={openCaptureCameraDialog}>
                                    <PhotoCameraIcon />
                                </Avatar>
                            }
                        </Box>
                    </Box>
                    <Box sx={{flexGrow: 2, ml: 2, pr: 2}}>
                        <InputLabel htmlFor="display-form-username">{at('input_username_label')}</InputLabel>
                        <OutlinedInput value={user?.username} 
                            id="display-form-username"
                            sx={{width:'100%'}}
                            disabled={true} 
                            label={at('input_username_label')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={openEditUsernameDialog}
                                    edge="end">
                                    <EditIcon />
                                    </IconButton>
                                </InputAdornment> } 
                        />

                        <InputLabel sx={{mt: 2}} htmlFor="display-form-email">{at('input_email_label')}</InputLabel>
                        <OutlinedInput value={user?.email} 
                            id="display-form-email"
                            sx={{width:'100%'}}
                            disabled={true} 
                            label={at('input_email_label')} />

                        <InputLabel sx={{mt: 2}} htmlFor="display-form-password">{at('input_password_label')}</InputLabel>
                        <OutlinedInput value={"**********"} 
                            id="display-form-password"
                            sx={{width:'100%'}}
                            type="password"
                            disabled={true} 
                            label={at('input_password_label')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={openEditPasswordDialog}
                                    edge="end">
                                    <EditIcon />
                                    </IconButton>
                                </InputAdornment> }
                        />
                    </Box>
                </Box>

                <Box sx={{mt: 2, width: '100%', p: 2}}>
                    <Typography variant="h3">{t('parameter_title')}</Typography>
                    <UserSettingsForm />
                </Box>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 10, mb: 2}}>
                    <Button variant="outlined" color="error" onClick={deleteAccount} >
                        {t('delete_account')}
                    </Button>
                </Box>


                <EditUsernameDialog open={showEditUsernameDialog} onCancel={closeEditUsernameDialog} />
                <EditPasswordDialog open={showEditPasswordDialog} onCancel={closeEditPasswordDialog} />
                <ViewProfilePictureDialog open={showViewProfilePictureDialog} onCancel={closeViewProfilePictureDialog} picture={profilePicture!}/>
                <CaptureCameraDialog open={showCaptureCameraDialog} onCancel={closeCaptureCameraDialog} onValidate={handlePictureCapture}/>

            </Content>
        </>
    );
}

interface IUserSettingsForm {
    postDisplay: UserSettingsPostDisplay
}

/**
 * 
 * @returns 
 */
const UserSettingsForm = () => {
    const { t } = useTranslation('account');
    const dispatch = useAppDispatch();
    const { settings } = useAppSelector(userSelector);
    
    /**
     * 
     */
    const validationSchema = Yup.object({
        postDisplay: Yup.mixed<UserSettingsPostDisplay>().oneOf(Object.values(UserSettingsPostDisplay)).required()
    });

    const onSubmit = async (data: IUserSettingsForm) => {
        await dispatch(userSlice.updateSettings(data));
    }

    /**
     * 
     */
    const { control, handleSubmit, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            postDisplay: settings!.postDisplay
        }
    });

    const debouncedChangeHandler = useCallback(
        debounce(handleSubmit(onSubmit), 1000)
      , []);

    useEffect(() => {
        const subscription = watch(() => debouncedChangeHandler());
        return () => subscription.unsubscribe()
      }, [watch])

    return (<>
        
            <Box sx={{display:'flex', justifyContent: 'space-between', width: '100%', mt: 2}} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography>{t('settings_display_post_medias')}</Typography>
                <Controller
                    name="postDisplay"
                    control={control}
                    render={({ field }) => (
                        <Select {...field}>
                            <MenuItem value={UserSettingsPostDisplay.SLIDESHOW}>{t('settings_display_post_medias_slideshow')}</MenuItem>
                            <MenuItem value={UserSettingsPostDisplay.COLUMN}>{t('settings_display_post_medias_column')}</MenuItem>
                        </Select>
                    )}
                />
            </Box>
        
    </>);
}

/**
 * 
 */
const EditUsernameDialog = (props: {open: boolean, onCancel: () => void}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('account');
    const { t : tf} = useTranslation('account_form');
    const { user } = useAppSelector(userSelector);
    const [ username, setUsername] = useState<string>(user!.username);
    const [error, setError] = useState<string | null>(null);

    const validate = async () => {
        if(username.length === 0) {
            setError(tf('input_username_error_required'));
            return;
        }

        if(username !== user!.username) {
            const {success, err} = await userService.validateUsername(username!);
            if(!success) {
                setError(err ? err : translate('errors', 'enexpected'));
                return;
            } else {
                await dispatch(userSlice.updateUsername(username));
                successChannel.emit(t('notification_username_update_success'));
            }
        }

        props.onCancel();
    };

    const cancel = () => {
        props.onCancel();
    }

    return (
        <Dialog
            open={props.open}
            onClose={cancel}
            sx={{
                "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    minWidth: "70%",  // Set your width here
                },
                },
            }}>
            <DialogTitle>{t('dialog_update_username_title')}</DialogTitle>
            <DialogContent>
                <FormControl sx={{width: '100%', mt: 2}}>
                    <OutlinedInput
                        id="input_new_username"
                        value={username}
                        onChange={e => {
                            setUsername(e.target.value);
                        }} />
                    <FormHelperText sx={{color: "error.main"}}>{error}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={validate}>{t('validate_button')}</Button>
                <Button onClick={cancel}>{t('cancel_button')}</Button>
            </DialogActions>
        </Dialog>
    );
};


/**
 * 
 */
interface IPasswordFormInputs {
oldPassword: string
newPassword: string
confirmNewPassword: string
}

/**
 * 
 */
const initialValues: IPasswordFormInputs = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
};

/**
 * 
 */
const EditPasswordDialog = (props: {open: boolean, onCancel: () => void}) => {
    const { t } = useTranslation('account');
    const { t : at} = useTranslation('account_form');
    const formRef = useRef<HTMLFormElement>(null);
    const [ showOldPassword, setShowOldPassword ] = useState<boolean>(false);
    const [ showNewPassword, setShowNewPassword ] = useState<boolean>(false);
    
    /**
     * 
     */
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required(at('input_password_error_required')),
        newPassword: Yup.string().required(at('input_password_error_required')),
        confirmNewPassword: Yup.string().required(at('input_confirm_password_error_required')).oneOf([Yup.ref('newPassword'), ''], at('input_confirm_password_error_nomatch'))
    });

    /**
     * 
     */
    const { control, handleSubmit, reset, setError, formState: { errors } } = useForm<IPasswordFormInputs>({
        resolver: yupResolver(validationSchema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    });

    const validate = async (data: IPasswordFormInputs) => {
        const { oldPassword, newPassword } = data;
        
        const {success, err} = await userService.updatePassword(oldPassword, newPassword);
        if(!success) {
            setError('oldPassword', {message: err ? err :  translate('errors', 'enexpected')});
        } else {
            successChannel.emit(t('notification_password_update_success'));
            props.onCancel();
        }
    };

    const cancel = () => {
        reset();
        props.onCancel();
    }

    /**
     * 
     * @returns 
     */
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    /**
     * 
     * @param event 
     */
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Dialog
            open={props.open}
            onClose={cancel}
            sx={{
                "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    minWidth: "70%",  // Set your width here
                },
                },
            }}>
            <DialogTitle>{t('dialog_update_password_title')}</DialogTitle>
            <DialogContent>
            <Stack
                ref={formRef}
                component="form"
                noValidate
                spacing={2}
                onSubmit={handleSubmit(validate)}
                justifyContent="center"
                alignItems="center"
                >
                    <FormControl sx={{ m: 1, width: '100%' }} error={errors.oldPassword ? true : false}>
                        <InputLabel htmlFor="form-old-password">{t('input_old_password_label')}</InputLabel>
                        <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field }) => <OutlinedInput {...field} 
                            id="form-old-password"
                            type={showOldPassword ? 'text' : 'password'}
                            label={t('input_old_password_label')}
                            aria-describedby="form-password-error-text"
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment> } 
                            />} />
                            <FormHelperText id="form-password-error-text">{errors.oldPassword?.message}</FormHelperText>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '100%' }} error={errors.newPassword ? true : false}>
                        <InputLabel htmlFor="form-new-password">{t('input_new_password_label')}</InputLabel>
                        <Controller
                        name="newPassword"
                        control={control}
                        render={({ field }) => <OutlinedInput {...field} 
                            id="form-new-password"
                            type={showNewPassword ? 'text' : 'password'}
                            label={t('input_new_password_label')}
                            aria-describedby="form-password-error-text"
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment> } 
                            />} />
                            <FormHelperText id="form-password-error-text">{errors.newPassword?.message}</FormHelperText>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '100%' }} error={errors.confirmNewPassword ? true : false}>
                        <InputLabel htmlFor="form-confirm-password">{t('input_confirm_old_password_label')}</InputLabel>
                        <Controller
                        name="confirmNewPassword"
                        control={control}
                        render={({ field }) => <OutlinedInput {...field} 
                            id="form-confirm-password"
                            type="password"
                            label={t('input_confirm_old_password_label')}
                            aria-describedby="form-confirm-password-error-text" />} />
                        <FormHelperText id="form-confirm-password-error-text">{errors.confirmNewPassword?.message}</FormHelperText>
                    </FormControl>
            </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(validate)}>{t('validate_button')}</Button>
                <Button onClick={cancel}>{t('cancel_button')}</Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 * 
 */
const CaptureCameraDialog = (props: {open: boolean, onCancel: () => void, onValidate: (file: File) => void}) => {
    
    const onCancel = () => {
        props.onCancel();
    }

    const onValidate = (file: File) => {
        props.onValidate(file);
        props.onCancel();
    }

    return (
        <Dialog fullScreen open={props.open}>
            <CameraPhotoCapture 
                onCancel={onCancel}
                onValidate={onValidate}/>
        </Dialog>
    );
}

/**
 * 
 */
const ViewProfilePictureDialog = (props: {open: boolean, onCancel: () => void, picture : string}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('account');
    const theme= useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [crop, setCrop] = useState<PixelCrop>();
    const [ cropMinWidth, setCropMinWidth ] = useState<number>(100);
    const finalImageSize: number = 500;

    const onPictureLoad = () => {
        if(imgRef.current) {
            const imgWidth = imgRef.current.width;
            const imgHeight = imgRef.current.height;

            const widthRatio = imgWidth / imgRef.current!.naturalWidth;

            setCropMinWidth(finalImageSize * widthRatio);
            
            let cropSize = imgWidth;
            if(imgHeight < imgWidth) {
                cropSize = imgHeight;
            }

            setCrop({
                x: 0,
                y: 0,
                width: cropSize,
                height: cropSize,
                unit: 'px'
            });
        }
    };

    const validate = async () => {
        if(crop) {
            const cropX = crop.x * imgRef.current!.naturalWidth / imgRef.current!.width;
            const cropY = crop.y * imgRef.current!.naturalHeight / imgRef.current!.height;

            const cropWidth = crop.width * imgRef.current!.naturalWidth / imgRef.current!.width;
            const cropHeight = crop.height * imgRef.current!.naturalHeight / imgRef.current!.height;

            const canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");

            canvas.width = finalImageSize;
            canvas.height = finalImageSize;

            ctx!.drawImage(
                imgRef.current!, 
                cropX,
                cropY,
                cropWidth, 
                cropHeight,
                0,
                0,
                finalImageSize, 
                finalImageSize
            );

            const pictureFile = dataURLtoFile(canvas.toDataURL("image/png"), `profile-${new Date().toISOString()}`);
            dispatch(await userSlice.updateProfilePicture(pictureFile));
        }
        props.onCancel();
    };

    const cancel = () => {
        props.onCancel();
    }

    // 

    return (
        <Dialog
            open={props.open}
            onClose={cancel}
            fullScreen={fullScreen}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        "& .MuiDialogContent-root" : {
                            paddingBottom: 0
                        }
                    }
                },
            }}>
            <DialogTitle>{t('dialog_update_profile_picture_title')}</DialogTitle>
            <DialogContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                    <ReactCrop crop={crop} onChange={c => setCrop(c)} circularCrop={true} aspect={1} minWidth={cropMinWidth}>
                        <img src={props.picture} ref={imgRef} style={{
                            maxHeight: 'calc(100vh - 187px)', 
                            maxWidth: '100%'
                        }} onLoad={onPictureLoad}/>
                    </ReactCrop>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={validate}>{t('validate_button')}</Button>
                <Button onClick={cancel}>{t('cancel_button')}</Button>
            </DialogActions>
        </Dialog>
    );
};
