import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

const video: CSSProperties = {
    maxHeight: '100%',
    maxWidth:'100%',
    width:'100%',
    position:'fixed',
    top: '50%',
    left:'50%',
    transform: 'translate(-50%, -50%)'
};

const cancelContainer: SxProps<Theme> = {
    position: 'fixed',
    top: 5,
    left: 5
}

const iconButton: SxProps<Theme> = {
    border:'1px solid white'
}

const icon: SxProps<Theme> = {
    color:'white'
}

const controlContainer: SxProps<Theme> = {
    position: 'fixed',
    top: 'calc(100% - 50px)',
    left: '50%',
    transform: 'translateX(-50%)'
};

export default {
    video,
    iconButton,
    icon,
    cancelContainer,
    controlContainer
}