import { Header } from "@components/Header";
import { Content } from "@components/Content";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { InstagramAccount } from "@features/post/instagram/models";
import { Loading } from "@components/Loading";
import { instagramService } from "@features/post/instagram/services";
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { successChannel } from '@channels/globalNotificationChannel'



export const UserExternalAccounts = () => {
    const { t } = useTranslation('user_external_accounts')


    return (
        <>
            <Header>
                <Typography variant="h2">{t('title')}</Typography>
            </Header>
            <Content>
                <InstagramAccounts />
            </Content>
        </>
    );
}


/**
 *
 */
const InstagramAccounts = () => {
    const { t } = useTranslation('user_external_accounts')
    const confirm = useConfirm();
    const [ instagramAccounts, setInstagramAccounts ] = useState<Array<InstagramAccount> | null>(null);

    useEffect(() => {
        (async () => {
            setInstagramAccounts(await instagramService.getAccounts());
        })();
    }, []);

    const deleteAccount = async (account: InstagramAccount) => {
        try {
            await confirm({description: t('confirm_delete_instagram_account', {name: account.label})});
            await instagramService.deleteAccount(account.id);
            setInstagramAccounts(instagramAccounts!.filter(acc => acc.id != account.id));
            successChannel.emit(t('notification_delete_instagram_account_success'));
        }
        catch(err){}
    };

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h3" sx={{mb: 1}}>{t('instagram_title')}</Typography>
            {instagramAccounts == null && <Loading />}
            {instagramAccounts != null && instagramAccounts.length == 0 && <Typography sx={{textAlign: 'center'}}>{t('no_instagram_accounts')}</Typography>}
            {instagramAccounts != null && instagramAccounts.length > 0 && 
                instagramAccounts.map((account, i)  => 
                    <Box key={account.id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, bgcolor: i % 2 == 0 ? 'grey.100' : 'white'}}>
                        <Typography>{account.label}</Typography>
                        <IconButton aria-label="delete" color="error" onClick={() => deleteAccount(account)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            }
        </Box>
    );
}