import { IconButton } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

interface CaptureCameraButtonProps {
    handleCapture: Function
}

export const CaptureCameraButton = (props: CaptureCameraButtonProps) => {

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleCapture(event);
    }

    return (
        <>
            <input
                accept="video/*"
                id="inputfile-camera-picture"
                type="file"
                capture="environment"
                onChange={handleCapture}
                style={{display:'none'}}
            />
            <label htmlFor="inputfile-camera-picture">
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span">
                    <AddAPhotoIcon fontSize="large" color="primary" />
                </IconButton>
            </label>
        </>
    );
};