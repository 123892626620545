import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "@app/hooks";
import { Post } from '@features/post/models';
import circlePostService, { LoadOrder } from '@features/circle/services/circlePostService';
import { CirclePost } from "@features/circle/models";

export interface CirclePostState {
  posts: Array<CirclePost>
  loadedCircleId: string
  scrollTop: number,
  hasOlderPosts: boolean,
  hasMoreRecentPosts: boolean,
  //focusedPostId: string | null
}

const initialState: CirclePostState = {
  posts: [],
  loadedCircleId: "",
  scrollTop: 0,
  hasOlderPosts: true,
  hasMoreRecentPosts: true,
  //focusedPostId: null
};

const circlePostSlice = createSlice({
  name: "circlePost",
  initialState,
  reducers: {
    loadPosts: (state, action: PayloadAction<{hasOlderPosts: boolean, hasMoreRecentPosts: boolean, loadedCircleId: string, posts: Array<CirclePost>}>) => {
      state.posts = action.payload.posts;
      state.hasOlderPosts = action.payload.hasOlderPosts;
      state.hasMoreRecentPosts = action.payload.hasMoreRecentPosts;
      state.loadedCircleId = action.payload.loadedCircleId;
    },
    addOlderPosts: (state, action: PayloadAction<{hasOlderPosts: boolean, posts: Array<CirclePost>}>) => {
      state.posts = state.posts.concat(action.payload.posts);
      state.hasOlderPosts = action.payload.hasOlderPosts;
    },
    addMoreRecentPosts: (state, action: PayloadAction<{hasMoreRecentPosts: boolean, posts: Array<CirclePost>}>) => {
      state.posts = action.payload.posts.concat(state.posts);
      state.hasMoreRecentPosts = action.payload.hasMoreRecentPosts;
    },
    resetCirclePostsState: () => {
      return initialState;
    },
    scrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    clearScroll: (state) => {
      state.scrollTop = 0;
    },
    setFocusOnPost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.map(p => {
        if(action.payload === p.id) {
          return {
            ...p,
            focused: true
          }
        } 
        return {
          ...p,
          focused: false
        }
      });
    },
    removePost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter(p => p.id !== action.payload);
    }
  },
});

/**
 * 
 * @param circleId 
 * @returns 
 */
const loadPostsFromLastRead = (circleId: string): AppThunkAction<Promise<Array<CirclePost>>> => {
  return async (dispatch, getState) => {
    const postsData = await circlePostService.loadPosts(circleId);

    const { circle } = getState().circle;

    const posts = await dispatch(circlePostSlice.actions.loadPosts({
      hasMoreRecentPosts: postsData.hasMoreRecentPosts,
      hasOlderPosts: postsData.hasOlderPosts,
      loadedCircleId: circleId,
      posts: postsData.posts.map(p => {
        if(circle && circle.lastReadPostId) {
          return {
            ...p,
            focused: circle.lastReadPostId === p.id
          }
        }
        
        return{
          ...p,
          focused: false
        };
      })
    }));

    return posts.payload.posts;
  }
};

/**
 * 
 * @param circleId 
 * @param lastPostId 
 * @returns 
 */
const loadOlderPosts = (circleId: string, lastPostId: string): AppThunkAction=> {
  return async (dispatch) => {
    const postsData = await circlePostService.loadPosts(circleId, lastPostId, LoadOrder.OLDER);
    
    dispatch(circlePostSlice.actions.addOlderPosts({
      hasOlderPosts: postsData.hasOlderPosts,
      posts: postsData.posts
    }));
    dispatch(circlePostSlice.actions.setFocusOnPost(lastPostId));
  }
};

/**
 * 
 * @param circleId 
 * @param lastPostId 
 * @returns 
 */
const loadMoreRecentPosts = (circleId: string, lastPostId: string): AppThunkAction => {
  return async (dispatch) => {
    const postsData = await circlePostService.loadPosts(circleId, lastPostId, LoadOrder.MORE_RECENT);
    await dispatch(circlePostSlice.actions.addMoreRecentPosts({
      hasMoreRecentPosts: postsData.hasMoreRecentPosts,
      posts: postsData.posts
    }));
  }
};

/**
 * 
 * @param circleId 
 * @param post 
 * @returns 
 */
const addPost = (circleId: string, post: Post): AppThunkAction => {
  return async (dispatch) => {
    await circlePostService.addPost(circleId, post.id);
    await dispatch(circlePostSlice.actions.resetCirclePostsState());
  }
};

/**
 * 
 * @param circleId 
 * @param post 
 */
const removePostFromCircle = (circleId: string, postId: string) : AppThunkAction => {
  return async (dispatch) => {
    await circlePostService.removePost(circleId, postId);
    await dispatch(circlePostSlice.actions.removePost(postId));
  };
};

/**
 * 
 * @param circleId 
 * @param post 
 * @returns 
 */
const removeCircleFromPost = (circleId: string, post: Post) : AppThunkAction => {
  return async (dispatch) => {
    await circlePostService.removePost(circleId, post.id);
    await dispatch(resetStateIfPostsPresent([post.id]));
  }
}

/**
 * Remove posts from already loaded posts if present
 * Could happen when deleting a post from post view page
 * with already loaded posts
 * @param id 
 */
const resetStateIfPostsPresent = (ids: string[]): AppThunkAction => {
  return async (dispatch, getState) => {
    const { posts } = getState().circlePost;
    // check if at least one of the given ids is in the list of posts
    if(posts.find(p => ids.includes(p.id))) {
      await dispatch(circlePostSlice.actions.resetCirclePostsState());
    }
  }
};

export const circlePostSelector = (state: { circlePost: CirclePostState }) => state.circlePost;

export const reducer = circlePostSlice.reducer;

const slice = {
  addPost,
  removePostFromCircle,
  loadMoreRecentPosts,
  loadOlderPosts,
  loadPostsFromLastRead,
  removeCircleFromPost,
  resetStateIfPostsPresent,
  resetCirclePostsState: circlePostSlice.actions.resetCirclePostsState, 
  scrollTop: circlePostSlice.actions.scrollTop, 
  //setFocusOnPost: circlePostSlice.actions.setFocusOnPost
};

export default slice;