import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";

import { useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { UserAvatar } from "@components/icons/UserAvatar";
import { Loading } from "@components/Loading";

import { commonSelector } from "@features/common/slices/commonSlice";

import { computeThumbnailPostSize } from "@features/post/helpers/post.helper";
import { ThumbnailPost } from "@features/post/components/ThumbnailPost";

import { CircleAuthorization, CirclePost } from '@features/circle/models';
import { circleSelector, circlePostSelector, circleUserSelector } from "@features/circle/slices";
import { circlePostService } from '@features/circle/services';

import { convertToReadableDate } from "@helpers/date.helper";
import { ViewCirclePostDialog } from "../components/ViewCirclePostDialog";

export const ViewCircleDetails = () => {
    const { t } = useTranslation('circle');
    const { t: cpt } = useTranslation('circle_post')
    const navigate = useNavigate();
    const { circle } = useAppSelector(circleSelector);
    const { users } = useAppSelector(circleUserSelector);
    const [ lastPosts, setLastPosts ] = useState<Array<CirclePost> | null>(null);
    const [postSize, setPostSize] = useState<number>(0);
    const { posts, loadedCircleId } = useAppSelector(circlePostSelector);
    const { containerSize } = useAppSelector(commonSelector);

    const [ selectedPost, setSelectedPost ] = useState<CirclePost | null>(null);


    useEffect(() => {
        if(!circle) {
            return;
        }

        (async () => {
            const {postPerRow, postSize } = computeThumbnailPostSize(containerSize);
            setPostSize(postSize);

            // no circle loaded - so no post => because of reloading the page 
            if(loadedCircleId === "") {
                const {posts: lastPosts} = await circlePostService.loadLastPosts(circle.id, postPerRow);
                setLastPosts(lastPosts);
            }  
            // take already loaded posts, no matter if there is more recent posts that haven't been loaded yet
            else if(posts && posts.length > 0) {
                const {postPerRow, postSize } = computeThumbnailPostSize(containerSize);
                setLastPosts(posts.slice(0, postPerRow));   
            }
        })();
    }, [circle]);

    const displayPost = (post: CirclePost) => {
        setSelectedPost(post);
    };

    const closeDialog = () => {
        setSelectedPost(null);
    }

    /**
     * 
     */
    const navigateToAllPosts = () => {
        navigate(generatePath(Paths.Authenticated.circleViewAllPosts, {"circleId": circle!.id}));
    };

    if(!circle) {
        return (<></>);
    }

    return (
        <Box>
            <Box sx={{display: 'flex', p: 2}}>
                <Avatar sx={{width: '150px', height: '150px'}}>{circle.name.charAt(0).toUpperCase()}</Avatar>
                <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', ml: 2, gap: 1}}>
                    <Typography variant="h2">{circle.name}</Typography>
                    <Typography>{t('created', {"date": convertToReadableDate(circle.creationDate)})}</Typography>
                </Box>
            </Box>
            
            <Box sx={{mt: 4}}>
                <Box sx={{p: 2, display:'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h3">{t('details_posts')}</Typography>
                    <Typography onClick={navigateToAllPosts} sx={{cursor:"pointer"}}>{`${circle.nbPosts} >`}</Typography>
                </Box>
                    {lastPosts == null && <Loading />}
                    {lastPosts && lastPosts.length === 0 && <Typography sx={{textAlign: 'center'}}>{cpt('no_post')}</Typography>}

                    {lastPosts && lastPosts.length > 0 && 
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                            {lastPosts.map(post => <ThumbnailPost post={post} size={postSize} key={post.id} onClick={() => displayPost(post)} />)}
                        </div>
                    }
            </Box>

            {selectedPost && <ViewCirclePostDialog post={selectedPost} open={selectedPost != null} onClose={closeDialog} />}

            {circle.authorizations.includes(CircleAuthorization.SHOW_ALL_USERS) && 
                <Box sx={{p: 2, mt: 4}}>
                    <Typography variant="h3">{t('details_users')}</Typography>
                    <List>
                        {users?.map(user =>  
                            <ListItem key={user.id}>
                                <ListItemAvatar>
                                    <UserAvatar user={user} />
                                </ListItemAvatar>
                                <ListItemText primary={user.username} />
                            </ListItem>
                        )}
                    </List>
                </Box>
            }
        </Box>
    );
};