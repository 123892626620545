import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Button, ButtonBase, ButtonBaseProps, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton, TabContext, TabList, TabPanelProps, useTabContext } from "@mui/lab";
import { useTheme } from '@mui/material/styles';
import { useConfirm } from "material-ui-confirm";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import Paths from "@app/routes";

import { Content } from "@components/Content"
import { Header } from "@components/Header"
import { AddFabButton } from "@components/AddFabButton";
import { LazyScrollingContent } from "@components/LazyScrollingContent";
import { Loading } from "@components/Loading";

import { Contact, ContactInvitation } from "@features/contact/models";
import { contactSlice, contactSelector } from "@features/contact/slices";
import { contactInvitationService } from '@features/contact/services';
import { hasPrivilege } from "@helpers/security.helper";
import { UserPrivilege } from "@features/user/models";
import { userSelector } from "@features/user/slices";

const CONTACTS_TAB = 'all';
export const INVITATIONS_TAB = 'invitations';


export const ViewContactsAndInvitations = () => {
    const { t } = useTranslation('manage_contacts_invitations');
    const navigate = useNavigate();
    const theme = useTheme();
    const { tab } = useParams();

    let defaultTab = CONTACTS_TAB;
    if(tab && tab === INVITATIONS_TAB) {
        defaultTab = INVITATIONS_TAB;
    }
    
    const [value, setValue] = useState(defaultTab);
    const [ nbReceivedInvitations, setNbReceivedInvitations ] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(generatePath(Paths.Authenticated.manageContacts, {tab: newValue}));
        setValue(newValue);
    };

    const invitationTitle = t('tab_title_invitations');

    const invitationTabButton = React.forwardRef<HTMLButtonElement, ButtonBaseProps>((props: ButtonBaseProps, ref) => (
            <ButtonBase {...props} ref={ref} disableRipple={true}>
                {invitationTitle}
                {nbReceivedInvitations > 0 && 
                    <Typography variant="subtitle2" component="span"
                        sx={{
                            bgcolor: 'secondary.main',
                            color:'secondary.contrastText',
                            px: '6px',
                            borderRadius: '10px',
                            position: 'absolute',
                            top:'3px',
                            // take the title length and size it depending on the fontSize in rem, divide by 2 because already centered by default
                            // so only need to move it for the half of the length + the text to display + 1 for space
                            marginLeft: (invitationTitle.length * parseFloat(`${theme.typography.button.fontSize}`) / 2 + `${nbReceivedInvitations}`.length * parseFloat(`${theme.typography.subtitle2.fontSize}`) + 1) + 'rem'
                        }}>{nbReceivedInvitations}</Typography>
                }
                {props.children}
            </ButtonBase>
        ));

    const updateReceivedInvitationsBadge = (nb: number) => {
        setNbReceivedInvitations(nb);
    };

    return (
        <>
         <Header>
            <Typography variant="h2" noWrap>{t('page_title')}</Typography>
         </Header>
         <Content>
            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} variant="fullWidth">
                            <Tab label={t('tab_title_contacts')} value={CONTACTS_TAB} disableRipple={true}/>
                            <Tab value={INVITATIONS_TAB} component={invitationTabButton} disableRipple={true}/>
                        </TabList>
                    </Box>
                    <TabPanel value={CONTACTS_TAB}>
                        <ManageContact />
                    </TabPanel>
                    <TabPanel value={INVITATIONS_TAB}>
                        <ManageInvitations updateReceivedInvitations={updateReceivedInvitationsBadge}/>
                    </TabPanel>
                </TabContext>
            </Box>            
         </Content>
        </>
     )
};


const  TabPanel = (props: TabPanelProps) => {
    const { children, value, ...other } = props;
    const tabContext = useTabContext();

    return (
      <Box
        component="div"
        role="tabpanel"
        hidden={value !== tabContext?.value}
        sx={{p: 1}}
        {...other}
      >
        {children}
      </Box>
    );
}


/**
 * 
 * @returns 
 */
const ManageInvitations = (props: {updateReceivedInvitations: (nb: number) => void}) => {
    const { t } = useTranslation('manage_contacts_invitations');
    const { t: ct } = useTranslation('common');
    const confirm = useConfirm();
    const { updateReceivedInvitations } = props;

    const [ receivedInvitations, setReceivedInvitations ] = useState<Array<ContactInvitation> | null>(null);
    const [ sentInvitations, setSentInvitations ] = useState<Array<ContactInvitation> | null>(null);

    const [ acceptLoading, setAcceptLoading ] = useState<boolean>(false);


    /*==> voir si on ne peut pas charger le tab invitation dès le départ
    ==> sinon a remonter au niveau du dessus? 
    ==> sinon on ne voit pas le nombre d'invit recu avant de cliquer sur l'onglet*/
    useEffect(() => {
        (async () => {
            const invitations = await contactInvitationService.loadInvitations();
            setReceivedInvitations(invitations.receivedInvitations);
            setSentInvitations(invitations.sentInvitations);
            updateReceivedInvitations(invitations.receivedInvitations.length);
        })();
    }, []);

    /**
     * 
     */
    const cancelInvitation = async (invitation: ContactInvitation) => {
        if(sentInvitations) {
            try{
                await confirm({description: t('confirm_cancel_invit_content', {'userLabel': invitation.userLabel})});
                await contactInvitationService.cancelInvitation(invitation.id);
                setSentInvitations(sentInvitations.filter(i => i.id!== invitation.id));
            } catch(err){}
        }
    };

    /**
     * 
     */
    const refuseInvitation = async (invitation: ContactInvitation) => {
        if(receivedInvitations) {
            try {
                await confirm({description: t('confirm_refuse_invit_content', {'userLabel': invitation.userLabel})});
                await contactInvitationService.refuseInvitation(invitation.id);
                setReceivedInvitations(receivedInvitations.filter(i => i.id!== invitation.id));
                updateReceivedInvitations(receivedInvitations.length);
            } catch(err){}
        }
    };

    /**
     * 
     * @param invitation 
     * @returns 
     */
    const acceptInvitation = async (invitation: ContactInvitation) => {
        setAcceptLoading(true);
        await contactInvitationService.acceptInvitation(invitation.id);
        if(receivedInvitations) {
            setReceivedInvitations(receivedInvitations.filter(i => i.id!== invitation.id));
        }
        setAcceptLoading(false);
    };

    if(receivedInvitations == null || sentInvitations == null) {
        return <Loading />;
    }

    return (
        <>
            <Typography variant="h3">{t('title_received_invitations')}</Typography>
            {receivedInvitations.length === 0 &&  <Typography sx={{ textAlign: 'center', my: 2 }}>{t('no_received_invitations')}</Typography>}
            {receivedInvitations.length > 0 && 
                <List sx={{ width: '100%' }}>
                    {receivedInvitations.map(invit =>
                        <ListItem key={invit.id}>
                            <ListItemAvatar>
                                <Avatar>C</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={invit.userLabel} />
                            <LoadingButton loading={acceptLoading} onClick={() => acceptInvitation(invit)}>{ct('accept_button')}</LoadingButton>
                            <Button variant="outlined" color="error" onClick={() => refuseInvitation(invit)}>{ct('refuse_button')}</Button>
                        </ListItem>
                    )}
                </List>
            }

            <Typography variant="h3">{t('title_sent_invitations')}</Typography>
            {sentInvitations.length === 0 &&  <Typography sx={{ textAlign: 'center', my: 2 }}>{t('no_sent_invitations')}</Typography>}
            {sentInvitations.length > 0 && 
                <List sx={{ width: '100%' }}>
                    {sentInvitations.map(invit =>
                        <ListItem key={invit.id}>
                            <ListItemAvatar>
                                <Avatar>C</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={invit.userLabel} />
                            <Button variant="outlined" color="error" onClick={() => cancelInvitation(invit)}>{ct('delete_button')}</Button>
                        </ListItem>
                    )}
                </List>
            }
        </>
    );
};


/**
 * 
 */
const ManageContact = () => {
    const { t } = useTranslation('manage_contacts_invitations');
    const { user } = useAppSelector(userSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const confirm = useConfirm();

    const { contacts, hasMoreContacts } = useAppSelector(contactSelector);

    /**
     * 
     * @returns 
     */
    const loadContacts = async () => {
        await dispatch(contactSlice.loadContacts());
    };

    /**
     * 
     * @param contact 
     */
    const deleteContact = async (contact: Contact) => {
        try {
            await confirm({description: t('confirm_delete_contact_content', {'contact': contact.username})});
            await dispatch(contactSlice.deleteContact(contact));
        } catch(err) {}
    }

    /**
     * 
     */
    const navigateToAddContact = () => {
        navigate(Paths.Authenticated.addContact);
    }

    return (
        <>
            <LazyScrollingContent height="100%" loadNext={loadContacts} hasOlderItems={hasMoreContacts} items={contacts} noElementText={t('no_contact')}
                render={(contacts: Array<Contact>) => 
                    <List sx={{ width: '100%'}}>
                        {contacts.map(contact => 
                            <ListItem key={contact.userId} 
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteContact(contact)}>
                                        <DeleteIcon color="error"/>
                                    </IconButton>
                                }>
                                <ListItemAvatar>
                                    <Avatar>C</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={contact.username}/>
                            </ListItem>
                        )}
                    </List>
                } />

            { hasPrivilege(user, UserPrivilege.CONTACT_SEARCH_ADD) && <AddFabButton onClick={navigateToAddContact}/> }
        </>
    );
};