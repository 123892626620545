
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk'
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store;
export type AppDispatch = typeof store.dispatch;