import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from './i18n/fr.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: fr
};

// i18n.changeLanguage
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    fallbackNS: 'common',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;

  export const translate = (ns: string, code: string): string => {
    return i18n.t(code, {ns: ns});
  }