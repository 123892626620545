import axios from '@app/axios';
import { Contact, SearchContact } from '@features/contact/models';

interface ContactPage {
    contacts: Array<Contact>
    hasNext: boolean
}

/**
 * 
 * @returns 
 */
const loadContacts = async (page: number): Promise<ContactPage> => {
    const { data } = await axios.get(`/contacts?page=${page}`);
    return data;
};

/**
 * 
 * @param search 
 */
const searchContact = async (search: string): Promise<Array<SearchContact>> => {
    const { data } = await axios.get(`/contacts/search?query=${search}`);
    return data;
};

/**
 * 
 */
const deleteContact = async (contactId: string) => {
    await axios.delete(`/contacts/${contactId}`);
}


const service = {
    loadContacts,
    deleteContact,
    searchContact
};

export default service;