import { Post, PostMediaStatus, PostMediaType, PostStatus } from "@features/post/models";
import { GenericPostType, GenericThumbnailPost } from '@features/post/components/GenericThumbnailPost';

interface ThumbnailPostProps {
    post: Post
    size: number
    checked ?: boolean
    selectable ?: boolean
    onClick ?: (post: Post) => void
    onLongPress ?: (post: Post) => void
}

export const ThumbnailPost = (props: ThumbnailPostProps) => {
    const post = props.post;
    
    const onClick = () => {
        if(props.onClick) {
            props.onClick(post);
        }
    }

    const onLongPress = () => {
        if(props.onLongPress) {
            props.onLongPress(post);
        }
    }

    let thumbnailUri = null;

    // only get the first media thumbnail for active post
    // processing posts have to implement polling so we can check periodically if the posts has been successfully processed
    if(props.post.status === PostStatus.ACTIVE) {
        const firstMedia = post.medias && post.medias.length > 0  ? post.medias[0] : null;
        if(firstMedia) {
            thumbnailUri = firstMedia.status === PostMediaStatus.ACTIVE ? firstMedia.thumbnailUri : null;
        }
    }

    const genericPost = {
        id: post.id,
        description: post.description,
        type: post.medias.length > 1 ? GenericPostType.ALBUM : (post.medias.length === 1 && post.medias[0].type === PostMediaType.VIDEO ? GenericPostType.VIDEO : GenericPostType.DEFAULT),
        thumbnailUri: thumbnailUri
    };

    return <GenericThumbnailPost onLongPress={onLongPress} onClick={onClick} size={props.size} post={genericPost} checked={props.checked} selectable={props.selectable}/>
}