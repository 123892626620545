import { useEffect, useState } from "react";

export default function useCameraDevice() {
    const [hasCameraDevice, setHasCameraDevice] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            // check first if there are some camera device. At this point we probably don't have
            // the permission to retrieve the related data of these sources
            const devices = await navigator.mediaDevices.enumerateDevices();
            setHasCameraDevice(devices.filter(d => d.kind === 'videoinput').length > 0);
        })();
    }, []);

    return hasCameraDevice;
}