import { useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

interface Size {
  width: number;
  height: number;
}


export default function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  setRef: (node: T | null) => void, 
  size: Size
] {
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  });

  const setRoundedSize = ({ width, height }: Size) => {
    setSize({ width: Math.round(width), height: Math.round(height) });
  };

  useLayoutEffect(() => {
    ref && setRoundedSize(ref.getBoundingClientRect());
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useResizeObserver(ref, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return [setRef, size];
}